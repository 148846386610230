import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  tankData: [],
};
 
export const fetchTotalStorages = createAsyncThunk(
  "creditSlice/fetchTotalStorages",
  async (data) => {
    const response = await axios.get(
      `${url}/usr/fetchTotalCapacityOfStorages.action?projId=${data?.projId}`
    ); 
    return response.data?.data?.result;
  }
);
export const deleteTotalStorage = createAsyncThunk(
	"creditSlice/deleteTotalStorage",
	async (data) => {
	  const response = await axios.get(
		`${url}/usr/deleteTotalCapacityOfStorage.action?stId=${data?.stId}`
	  );  
	  return response.data;
	}
  );

  

export const tankSlice = createSlice({
  name: "tank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchTotalStorages.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTotalStorages.fulfilled, (state, action) => {
        state.loading = false;
        state.tankData = action.payload;
      })
      .addCase(fetchTotalStorages.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteTotalStorage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTotalStorage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTotalStorage.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default tankSlice.reducer;
export const {} = tankSlice.actions;
