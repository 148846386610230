import { Descriptions } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function BasicDetailStep({next}) {
	const { newProjectObj } = useSelector((state) => state.obj);

	const {
		usrFullNames,
		usrEmail,
		usrAltFirstName,
		usrAltLastName,
		usrMobileNumber,
		usrAltPhoneNo,
		usrNationalId,
	} = newProjectObj;

	useEffect(() => {}, [newProjectObj]);

	const items = [
		{
			key: '1',
			label: 'Contact person name',
			children: usrFullNames ?? 'N/A',
		},
		{
			key: '2',
			label: 'ID/ Passport No.',
			children: usrNationalId ?? 'N/A',
		},
		{
			key: '3',
			label: 'Email',
			children: usrEmail ?? 'N/A',
		},
		{
			key: '4',
			label: 'Phone number',
			children: usrMobileNumber ?? 'N/A',
		},
		{
			key: '5',
			label: 'Name of alternate contact person',
			children: usrAltFirstName + ' ' + usrAltLastName ?? 'N/A',
		},
		{
			key: '6',
			label: 'Phone no. of Alternate Contact Person',
			children: usrAltPhoneNo ?? 'N/A',
		},
	];
	
	return (
		<>
			<div className='info_desc_card'>
				<Descriptions
					column={1}
					colon={false}
					title='Basic Details'
					items={items}
				/>
			</div>

			<div className='mt-[8.75rem]'>
				<button onClick={() => next()} className='cstm-btn'>Next</button>
			</div>
		</>
	);
}
