import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  dateForHumans,
  formatMoney,
  formatPath,
  previewPaper,
} from "../../utils";
import { fetchInvoice } from "../../features/offer/offerSlice";
import { fetchWspProject } from "../../features/wsp/wspSlice";
import { Table } from "antd";
import svg11 from "../../assets/svg/svg11.svg";

function InvoiceList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, invData } = useSelector((state) => state.offer);
  const { wspApplications, sendProjId, isLoading, isSuccess } = useSelector(
    (state) => state.wsp
  );
  const { user } = useSelector((state) => state.auth);

  const columns = [
    {
      title: "Name",
      render: (item) => {
        return <div>{item?.accTradeName ?? wspApplications?.accTradeName}</div>;
      },
    },
    {
      title: "Amount Request",
      render: (item) => {
        return <div>{formatMoney(item)}</div>;
      },
      dataIndex: "pjdAmountRequest",
    },
    {
      title: "Account Number",
      dataIndex: "pjdAccountNumber",
    },
    {
      title: "Bank Name",
      dataIndex: "pjdBankName",
    },
    {
      title: "Bank Branch",
      dataIndex: "pjdBranch",
    },
    {
      title: "Contract Number",
      dataIndex: "pjdContractNumber",
    },
    {
      title: "Contract Sum",
      dataIndex: "pjdContractSum",
    },
    {
      title: "Action",
      render: (item) => {
        return (
          <>
            <div
              className="text-[#0170BD] cursor-pointer"
              onClick={() => handleView(item)}
            >
              View
            </div>
          </>
        );
      },
    },
  ];

  async function fetchfetchInvoiceData() {
    dispatch(
      fetchInvoice({
        projId: wspApplications?.projId,
      })
    );
  }

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  useEffect(() => {
    fetchfetchInvoiceData();
    fetchWspProjectData();
  }, []);

  const handleAddInvoice = () => {
    navigate("/project-development");
  };

  const handleView = (item) => {
    navigate(`/invoice-view/${item?.pjdId}`);
  };

  console.log("invData", invData);
  return (
    <div>
      {invData && invData?.length > 0 ? (
        <>
          <div className="flex justify-between ">
            <div className="offer-title">Project Development</div>
            <button className="cstm-btn" onClick={handleAddInvoice}>
              Add new Invoice
            </button>
          </div>
          <div className="mt-10">
            <Table
              className="mt-[1px] w-full"
              scroll={{
                x: 800,
              }}
              rowKey={(record) => record?.pjdId}
              columns={columns}
              dataSource={invData}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <>
          <div className="offer-title">Project Development</div>
          <div className="mt-[11.56rem] flex flex-col justify-center items-center">
            <img src={svg11} alt="svg11" />
            <div className="no_data_header">Invoice</div>
            <div className="no_data_subheader">
              Add your payment request details below
            </div>

            <div className="mt-[3.69rem]">
              <button className="cstm-btn" onClick={handleAddInvoice}>
                Add Invoice
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default InvoiceList;
