import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const data = [

    { name: 'Kiambu', value: 300 },
    { name: 'Kajiado', value: 300 },
    { name: 'Machakos', value: 120 },
    { name: 'Makueni', value: 250 },
    { name: 'Nakuru', value: 170 },
];



const total = data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);

const COLORS = ['#BCDCFF', '#006FB4', '#123A65', '#123A65', '#123A65'];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
        <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const ContributionPieChart = ({ totalCount, activeCustomers, singleProject }) => {
    const singleData = [
        { name: "Active customer", value: activeCustomers },
        { name: "Total customer", value: totalCount },
    ]

    console.log(activeCustomers, "_______________ACTIVE CUSTOMER___________________", totalCount);

    return (
        <div className='w-[100%]'>
            <PieChart width={400} height={300}>
                <Pie
                    data={singleProject ? singleData : data}
                    cx={120}
                    cy={200}
                    innerRadius={65}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                    labelLine={false}
                // label={renderCustomizedLabel}
                >
                    {(singleProject ? singleData : data).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}


                </Pie>
            </PieChart>
            <div className='mb-2 text-blk font-[500] text-[1rem] '>Key</div>
            <ul className='flex flex-col'>
                {(singleProject ? singleData : data).map((entry, index) => (
                    <li key={`legend-${index}`} className='flex justify-between mt-2'>
                        <div className='mb-4' style={{ width: 20, height: 20, backgroundColor: COLORS[index % COLORS.length], marginRight: 8 }}></div>
                        <div className='flex flex-row w-[80%] justify-between'>
                            <div>{entry.name}</div>
                            <>{singleProject ? <div className='flex flex-row w-auto justify-between items-center px-2 text-center bg-green text-white'>
                                {entry.value}
                            </div> :
                                (<div className='flex flex-row w-[20%] justify-between items-center  px-1 bg-green'>
                                    <div><svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none">
                                        <g clip-path="url(#clip0_708_6103)">
                                            <path d="M0 8.00016L3.5 5.67537L7 8.00016V5.67537L3.5 3.35059L0 5.67537V8.00016Z" fill="#7FFF74" />
                                            <path d="M0 4.64957L3.5 2.32479L7 4.64957V2.32479L3.5 0L0 2.32479V4.64957Z" fill="#7FFF74" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_708_6103">
                                                <rect width="7" height="8" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></div>

                                    <div className='text-white'>  {(entry.value / total * 100).toFixed(0)}%</div>
                                </div>)
                            }
                            </>
                        </div>

                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ContributionPieChart;
