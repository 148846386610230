import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"; 
import { formatPath, previewPaper } from "../../utils";
import { fetchInvoice } from "../../features/offer/offerSlice";
import BreadCrumb from "../../layout/BreadCrumb";

function InvoiceView() {
  const { loading, invData } = useSelector((state) => state.offer);
  const { wspApplications, sendProjId, isLoading, isSuccess } = useSelector(
    (state) => state.wsp
  );
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const filtredData = invData?.find((item) => item.pjdId == id);

  const breadList = [
		{
			title: 'Project Development',
			href: '/invoice-list',
		}, 
    {
      title: <span className="">Invoice View</span>, 
		},
		 
	];

  async function fetchfetchInvoiceData() {
    dispatch(
      fetchInvoice({
        projId: wspApplications?.projId,
      })
    );
  }

  useEffect(() => {
    fetchfetchInvoiceData();
  }, []);

 
  return (
    <div className="ml-[2rem]">
     <div className="offer-title">Project Development - {filtredData?.accTradeName ?? wspApplications?.accTradeName}</div>
     <div className='mt-[.94rem]'>
					<BreadCrumb className="text-[16px]" breadList={breadList} />
				</div>

      <div className="flex justify-between mt-10"> 

        {filtredData?.pjdPaymentProof && (
          <div className="dvt-date flex flex-col">
            Proof of Payment -{" "}
            {filtredData?.accTradeName ?? wspApplications?.[0]?.accTradeName}
          </div>
        )}
      </div>

      <div className="flex flex-col">
        <div className="dvt-date !text-[#0170BD]">Date of request</div>
        <div className="dvt-details">4th July 2023</div>

        <div className="dvt-date mt-[1.80rem] !text-[#0170BD]">SSWP Details</div>
        <div className="dvt-date mt-[1.12rem] !text-[#0170BD]">
          Name of small scale water provider
        </div>
        <div className="dvt-details mt-[.44rem]">
          {wspApplications?.accTradeName} 
        </div>

        <div className="dvt-date mt-[1.12rem] !text-[#0170BD]">Name of contact person</div>
        <div className="dvt-details mt-[.44rem]">{user?.usrFullNames}</div>

        <div className="dvt-date mt-[1.12rem] !text-[#0170BD]">
          Phone number - Contact person
        </div>
        <div className="dvt-details mt-[.44rem]">{user?.usrMobileNumber}</div>

        <div className="dvt-date mt-[3.12rem] !text-[#0170BD]">Payment details</div>

        <div className="grid grid-cols-2 w-[50%] gap-y-5">
          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Name of supplier</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdSupplierName}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Bank Name</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdBankName}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Contract number</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdContractNumber}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Branch</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdBranch}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Contract sum</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdContractSum}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">SWIFT Code</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdSwiftCode}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Paid Amount</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdPaidAmount}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Amount Request</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdAmountRequest}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Balance Remaining</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdBalanceRemaining}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="dvt-date mt-[1.12rem]">Account number</div>
            <div className="dvt-details mt-[.44rem]">
              {filtredData?.pjdAccountNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceView;
