import { Descriptions } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function BusinessDetailStep({ next, prev }) {
	const { newProjectObj } = useSelector((state) => state.obj);

	const {
		accTradeName,
		accCounty,
		accSubCounty,
		accRole,
		accServices,
		projBankStatementFile,
	} = newProjectObj;

	useEffect(() => {}, [newProjectObj]);

	const items = [
		{
			key: '1',
			label: 'Water Project',
			children: accTradeName ?? 'N/A',
		},
		{
			key: '2',
			label: 'Role on the project',
			children: accRole ?? 'N/A',
		},
		{
			key: '3',
			label: 'Financial Statements ',
			children: projBankStatementFile ?? 'N/A',
		},
		{
			key: '4',
			label: 'County',
			children: accCounty ?? 'N/A',
		},
		{
			key: '5',
			label: 'Sub-County',
			children: accSubCounty ?? 'N/A',
		},
		{
			key: '6',
			label: 'Services offered',
			children: accServices ?? 'N/A',
		},
	];
	return (
		<>
			<div className='info_desc_card'>
				<Descriptions
					column={1}
					colon={false}
					title='Business Details'
					items={items}
				/>
			</div>

			<div className='mt-[8.75rem] flex flex-col'>
				<button onClick={() => next()} className='cstm-btn'>
					Next
				</button>
				<button onClick={() => prev()} className='border-btn mt-[.75rem]'>Previous</button>
			</div>
		</>
	);
}
