import { Link } from 'react-router-dom';
import partner from '../../assets/img/partner.png';

export default function BacomeAPartner() {
	return (
		<>
			<div className='flex w-full'>
				<div className='flex w-full'>
					<div
						style={{
							borderRadius: '0.25rem',
							background: 'rgba(8, 115, 185, 0.05)',
						}}
						className='py-[5.31rem] px-[8.19rem] w-full flex flex-col'>
						<span className='how_to_header_txt'>Become a partner</span>
						<div className='flex flex-col gap-y-[2.06rem] mt-[1.88rem]'>
							<div className='flex items-start gap-x-[.63rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='23'
									height='23'
									viewBox='0 0 23 23'
									fill='none'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M16.7418 2.36677C17.0225 2.08608 17.4775 2.08608 17.7582 2.36677L20.6332 5.24177C20.9139 5.52246 20.9139 5.97754 20.6332 6.25823L12.0082 14.8832C11.8734 15.018 11.6906 15.0938 11.5 15.0938H8.625C8.22805 15.0938 7.90625 14.772 7.90625 14.375V11.5C7.90625 11.3094 7.98198 11.1266 8.11677 10.9918L16.7418 2.36677ZM9.34375 11.7977V13.6562H11.2023L19.1085 5.75L17.25 3.89147L9.34375 11.7977Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M14.5855 4.52302C14.8662 4.24233 15.3213 4.24233 15.602 4.52302L18.477 7.39802C18.7577 7.67871 18.7577 8.13379 18.477 8.41448C18.1963 8.69517 17.7412 8.69517 17.4605 8.41448L14.5855 5.53948C14.3048 5.25879 14.3048 4.80371 14.5855 4.52302Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M3.29603 3.29603C3.56562 3.02645 3.93125 2.875 4.3125 2.875H12.2188C12.6157 2.875 12.9375 3.1968 12.9375 3.59375C12.9375 3.9907 12.6157 4.3125 12.2188 4.3125L4.3125 4.3125L4.3125 18.6875H18.6875V10.7812C18.6875 10.3843 19.0093 10.0625 19.4062 10.0625C19.8032 10.0625 20.125 10.3843 20.125 10.7812V18.6875C20.125 19.0687 19.9736 19.4344 19.704 19.704C19.4344 19.9736 19.0687 20.125 18.6875 20.125H4.3125C3.93125 20.125 3.56562 19.9736 3.29603 19.704C3.02645 19.4344 2.875 19.0687 2.875 18.6875V4.3125C2.875 3.93125 3.02645 3.56562 3.29603 3.29603Z'
										fill='#0170BD'
									/>
								</svg>
								<div className='flex flex-col'>
									<span className='how_to_step_txt'>
										<span className='!font-medium'>Step 1.</span> Submit your
										application
									</span>
									<span className='how_to_step_sub_txt mt-[.94rem]'>
										Tell us a little about yourself and your water project.
									</span>
								</div>
							</div>

							<div className='flex items-start gap-x-[.63rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='25'
									height='25'
									viewBox='0 0 25 25'
									fill='none'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M14.8121 3.70394C14.9239 3.28721 15.3524 3.04006 15.7691 3.1519C17.2266 3.54305 18.5556 4.31069 19.6226 5.37776C20.6897 6.44483 21.4573 7.7738 21.8485 9.23128C21.9603 9.64801 21.7132 10.0765 21.2964 10.1883C20.8797 10.3002 20.4512 10.053 20.3394 9.63629C20.0193 8.4435 19.3911 7.35589 18.5178 6.48261C17.6445 5.60933 16.5569 4.98111 15.3641 4.66099C14.9474 4.54915 14.7002 4.12067 14.8121 3.70394Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M14.001 6.72349C14.1117 6.30647 14.5396 6.05819 14.9566 6.16895C15.8867 6.41596 16.735 6.90447 17.4154 7.58495C18.0959 8.26543 18.5844 9.1137 18.8314 10.0438C18.9422 10.4608 18.6939 10.8887 18.2769 10.9994C17.8599 11.1102 17.432 10.8619 17.3213 10.4449C17.145 9.78099 16.7963 9.17551 16.3106 8.6898C15.8249 8.20409 15.2194 7.85541 14.5555 7.6791C14.1385 7.56834 13.8902 7.1405 14.001 6.72349Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M7.89876 2.38958C8.23643 2.34626 8.57897 2.41511 8.87368 2.58554C9.16707 2.75521 9.39685 3.0162 9.52801 3.32861L11.4881 7.90209C11.4883 7.90275 11.4886 7.9034 11.4889 7.90405C11.5904 8.13857 11.6328 8.39441 11.6124 8.64915C11.592 8.9046 11.509 9.1511 11.3709 9.36694L11.3657 9.37497L9.73793 11.8507C10.4644 13.349 11.6761 14.5578 13.1762 15.2807L13.1793 15.2785L15.6188 13.6489C15.8353 13.5028 16.0852 13.4136 16.3453 13.3896C16.6055 13.3656 16.8674 13.4077 17.107 13.5119C17.108 13.5123 17.1089 13.5127 17.1099 13.5132L21.6722 15.4726C21.9844 15.6038 22.2453 15.8335 22.4149 16.1268C22.5853 16.4215 22.6542 16.764 22.6109 17.1017C22.4419 18.4207 21.798 19.633 20.7998 20.5116C19.8016 21.3903 18.5175 21.8751 17.1877 21.8754C13.4581 21.8754 9.88104 20.3938 7.24381 17.7566C4.60658 15.1194 3.125 11.5425 3.125 7.81293C3.1253 6.48311 3.61014 5.19878 4.4888 4.20059C5.3674 3.20247 6.57982 2.55862 7.89876 2.38958ZM9.0332 12.1879L8.3285 12.5252C8.21312 12.2841 8.16151 12.0174 8.17861 11.7507C8.19571 11.484 8.28094 11.2261 8.42615 11.0017L8.42922 10.997L10.0549 8.52448L10.0534 8.52107L8.09051 3.94099C7.15109 4.0631 6.28768 4.52179 5.66164 5.23299C5.03407 5.94594 4.68776 6.86312 4.6875 7.81293M9.0332 12.1879L8.33006 12.5284C9.21091 14.3475 10.6818 15.8146 12.5031 16.6908L12.5075 16.6929C12.7527 16.809 13.0239 16.8592 13.2945 16.8387C13.5643 16.8182 13.8242 16.728 14.0487 16.5768C14.0493 16.5764 14.0498 16.5761 14.0504 16.5757L16.4886 14.9469L21.0589 16.9097L21.0601 16.9102C20.938 17.8496 20.4786 18.7128 19.7674 19.3388C19.0545 19.9664 18.1373 20.3127 17.1875 20.3129M4.6875 7.81293C4.68755 11.1281 6.0045 14.3076 8.34867 16.6518C10.6928 18.9959 13.8724 20.3129 17.1875 20.3129'
										fill='#0170BD'
									/>
								</svg>
								<div className='flex flex-col'>
									<span className='how_to_step_txt'>
										<span className='!font-medium'>Step 2.</span> We get in
										touch with you to discuss your application
									</span>
									<span className='how_to_step_sub_txt mt-[.94rem]'>
										Our representatives will review your application and reach
										out to explore synergies.
									</span>
								</div>
							</div>

							<div className='flex items-start gap-x-[.63rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='25'
									height='25'
									viewBox='0 0 25 25'
									fill='none'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M6.7974 5.18379C5.34812 5.97431 4.6875 6.938 4.6875 7.8125C4.6875 8.687 5.34812 9.65069 6.7974 10.4412C8.21714 11.2156 10.2319 11.7188 12.5 11.7188C14.7681 11.7188 16.7829 11.2156 18.2026 10.4412C19.6519 9.65069 20.3125 8.687 20.3125 7.8125C20.3125 6.938 19.6519 5.97431 18.2026 5.18379C16.7829 4.40939 14.7681 3.90625 12.5 3.90625C10.2319 3.90625 8.21714 4.40939 6.7974 5.18379ZM6.0492 3.81208C7.73978 2.88994 10.0219 2.34375 12.5 2.34375C14.9781 2.34375 17.2602 2.88994 18.9508 3.81208C20.6118 4.7181 21.875 6.09817 21.875 7.8125C21.875 9.52683 20.6118 10.9069 18.9508 11.8129C17.2602 12.7351 14.9781 13.2812 12.5 13.2812C10.0219 13.2812 7.73978 12.7351 6.0492 11.8129C4.38815 10.9069 3.125 9.52683 3.125 7.8125C3.125 6.09817 4.38815 4.7181 6.0492 3.81208Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M3.90625 7.03125C4.33772 7.03125 4.6875 7.38103 4.6875 7.8125V12.5C4.6875 13.374 5.34807 14.3377 6.79748 15.1284C8.21726 15.9029 10.232 16.4062 12.5 16.4062C14.768 16.4062 16.7827 15.9029 18.2025 15.1284C19.6519 14.3377 20.3125 13.374 20.3125 12.5V7.8125C20.3125 7.38103 20.6623 7.03125 21.0938 7.03125C21.5252 7.03125 21.875 7.38103 21.875 7.8125V12.5C21.875 14.2139 20.6117 15.594 18.9508 16.5C17.2602 17.4223 14.9781 17.9688 12.5 17.9688C10.0219 17.9688 7.73977 17.4223 6.0492 16.5C4.38825 15.594 3.125 14.2139 3.125 12.5V7.8125C3.125 7.38103 3.47478 7.03125 3.90625 7.03125Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M3.90625 11.7188C4.33772 11.7188 4.6875 12.0685 4.6875 12.5V17.1875C4.6875 18.0615 5.34807 19.0252 6.79748 19.8159C8.21726 20.5904 10.232 21.0938 12.5 21.0938C14.768 21.0938 16.7827 20.5904 18.2025 19.8159C19.6519 19.0252 20.3125 18.0615 20.3125 17.1875V12.5C20.3125 12.0685 20.6623 11.7188 21.0938 11.7188C21.5252 11.7188 21.875 12.0685 21.875 12.5V17.1875C21.875 18.9014 20.6117 20.2815 18.9508 21.1875C17.2602 22.1098 14.9781 22.6562 12.5 22.6562C10.0219 22.6562 7.73977 22.1098 6.0492 21.1875C4.38825 20.2815 3.125 18.9014 3.125 17.1875V12.5C3.125 12.0685 3.47478 11.7188 3.90625 11.7188Z'
										fill='#0170BD'
									/>
								</svg>
								<div className='flex flex-col'>
									<span className='how_to_step_txt'>
										<span className='!font-medium'>Step 3.</span> Selection of
										viable projects
									</span>
									<span className='how_to_step_sub_txt mt-[.94rem]'>
										Bid on the project you would like to support.
									</span>
								</div>
							</div>

							<div className='flex items-start gap-x-[.63rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='25'
									height='25'
									viewBox='0 0 25 25'
									fill='none'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M6.7974 5.18379C5.34812 5.97431 4.6875 6.938 4.6875 7.8125C4.6875 8.687 5.34812 9.65069 6.7974 10.4412C8.21714 11.2156 10.2319 11.7188 12.5 11.7188C14.7681 11.7188 16.7829 11.2156 18.2026 10.4412C19.6519 9.65069 20.3125 8.687 20.3125 7.8125C20.3125 6.938 19.6519 5.97431 18.2026 5.18379C16.7829 4.40939 14.7681 3.90625 12.5 3.90625C10.2319 3.90625 8.21714 4.40939 6.7974 5.18379ZM6.0492 3.81208C7.73978 2.88994 10.0219 2.34375 12.5 2.34375C14.9781 2.34375 17.2602 2.88994 18.9508 3.81208C20.6118 4.7181 21.875 6.09817 21.875 7.8125C21.875 9.52683 20.6118 10.9069 18.9508 11.8129C17.2602 12.7351 14.9781 13.2812 12.5 13.2812C10.0219 13.2812 7.73978 12.7351 6.0492 11.8129C4.38815 10.9069 3.125 9.52683 3.125 7.8125C3.125 6.09817 4.38815 4.7181 6.0492 3.81208Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M3.90625 7.03125C4.33772 7.03125 4.6875 7.38103 4.6875 7.8125V12.5C4.6875 13.374 5.34807 14.3377 6.79748 15.1284C8.21726 15.9029 10.232 16.4062 12.5 16.4062C14.768 16.4062 16.7827 15.9029 18.2025 15.1284C19.6519 14.3377 20.3125 13.374 20.3125 12.5V7.8125C20.3125 7.38103 20.6623 7.03125 21.0938 7.03125C21.5252 7.03125 21.875 7.38103 21.875 7.8125V12.5C21.875 14.2139 20.6117 15.594 18.9508 16.5C17.2602 17.4223 14.9781 17.9688 12.5 17.9688C10.0219 17.9688 7.73977 17.4223 6.0492 16.5C4.38825 15.594 3.125 14.2139 3.125 12.5V7.8125C3.125 7.38103 3.47478 7.03125 3.90625 7.03125Z'
										fill='#0170BD'
									/>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M3.90625 11.7188C4.33772 11.7188 4.6875 12.0685 4.6875 12.5V17.1875C4.6875 18.0615 5.34807 19.0252 6.79748 19.8159C8.21726 20.5904 10.232 21.0938 12.5 21.0938C14.768 21.0938 16.7827 20.5904 18.2025 19.8159C19.6519 19.0252 20.3125 18.0615 20.3125 17.1875V12.5C20.3125 12.0685 20.6623 11.7188 21.0938 11.7188C21.5252 11.7188 21.875 12.0685 21.875 12.5V17.1875C21.875 18.9014 20.6117 20.2815 18.9508 21.1875C17.2602 22.1098 14.9781 22.6562 12.5 22.6562C10.0219 22.6562 7.73977 22.1098 6.0492 21.1875C4.38825 20.2815 3.125 18.9014 3.125 17.1875V12.5C3.125 12.0685 3.47478 11.7188 3.90625 11.7188Z'
										fill='#0170BD'
									/>
								</svg>
								<div className='flex flex-col'>
									<span className='how_to_step_txt'>
										<span className='!font-medium'>Step 4.</span> Financial
										offer
									</span>
									<span className='how_to_step_sub_txt mt-[.94rem]'>
                                    Provide assistance to the projects that stand out most to you. 
									</span>
								</div>
							</div>
						</div>

						<Link
							to='/signup-ta/basic-details'
							className='cstm-btn mt-[3.63rem]'>
							Go to partner application
						</Link>
					</div>

					<div className='flex flex-shrink-0'>
						<img src={partner} className='' alt='become a partner' />
					</div>
				</div>
			</div>
		</>
	);
}
