import { Link } from 'react-router-dom';

export default function Dashboard() {
	return (
		<>
			<div className='w-full'>
				<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-[40px]'>
					<Link to='/' className='home_card'>
						<div className='flex items-center'>
							<svg
								stroke='currentColor'
								fill='currentColor'
								stroke-width='0'
								viewBox='0 0 24 24'
								color='#FFAE14'
								height='24'
								width='24'
								xmlns='http://www.w3.org/2000/svg'
								style={{ color: 'rgb(255, 174, 20)' }}>
								<path d='M12.14 2a10 10 0 1 0 10 10 10 10 0 0 0-10-10zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z'></path>
								<path d='M16.14 10a3 3 0 0 0-3-3h-5v10h2v-4h1.46l2.67 4h2.4l-2.75-4.12A3 3 0 0 0 16.14 10zm-3 1h-3V9h3a1 1 0 0 1 0 2z'></path>
							</svg>

							<span className='home_header_txt pl-[1rem]'>WSP Sign ups</span>
						</div>
						<div className='mt-[16px] flex items-center justify-between w-full'>
							<span className='home_card_txt'>
								last Registered on 24th June
							</span>
							<span className='home_card_txt_count'>33</span>
						</div>
					</Link>

					<Link to='/' className='home_card'>
						<div className='flex items-center'>
							<svg
								stroke='currentColor'
								fill='currentColor'
								stroke-width='0'
								viewBox='0 0 512 512'
								color='#0E71F1'
								height='24'
								width='24'
								xmlns='http://www.w3.org/2000/svg'
								style={{ color: 'rgb(14, 113, 241)' }}>
								<path d='M23 24v464h18V386.9c152.3 29.4 277.6 29.4 430 0V488h18V24h-18v16h-23v32h23v32h-23v32h23v232.6c-153.3 29.9-276.7 29.9-430 0V104h23V72H41V24H23zm41 48h32V40H64v32zm32 0v32h32V72H96zm32 0h32V40h-32v32zm32 0v32h32V72h-32zm32 0h32V40h-32v32zm32 0v32h32V72h-32zm32 0h32V40h-32v32zm32 0v32h32V72h-32zm32 0h32V40h-32v32zm32 0v32h32V72h-32zm32 0h32V40h-32v32zm32 0v32h32V72h-32zm0 32h-32v32h32v-32zm-64 0h-32v32h32v-32zm-64 0h-32v32h32v-32zm-64 0h-32v32h32v-32zm-64 0h-32v32h32v-32zm-64 0H64v32h32v-32z'></path>
							</svg>

							<span className='home_header_txt pl-[1rem]'>
								Completed Projects
							</span>
						</div>
						<div className='mt-[16px] flex items-center justify-between w-full'>
							<span className='home_card_txt'>KES 8,873,011,000</span>
							<span className='home_card_txt_count !text-blu'>12</span>
						</div>
					</Link>

					<Link to='/' className='home_card'>
						<div className='flex items-center'>
							<svg
								stroke='currentColor'
								fill='currentColor'
								stroke-width='0'
								viewBox='0 0 576 512'
								color='#11CB95'
								height='24'
								width='24'
								xmlns='http://www.w3.org/2000/svg'
								style={{ color: 'rgb(17, 203, 149)' }}>
								<path d='M288 256c53 0 96-42.1 96-94 0-40-57.1-120.7-83.2-155.6-6.4-8.5-19.2-8.5-25.6 0C249.1 41.3 192 122 192 162c0 51.9 43 94 96 94zm277.3 72.1c-11.8-10.7-30.2-10-42.6 0L430.3 402c-11.3 9.1-25.4 14-40 14H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h78.3c15.9 0 30.7-10.9 33.3-26.6 3.3-20-12.1-37.4-31.6-37.4H192c-27 0-53.1 9.3-74.1 26.3L71.4 384H16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h356.8c14.5 0 28.6-4.9 40-14L564 377c15.2-12.1 16.4-35.3 1.3-48.9z'></path>
							</svg>

							<span className='home_header_txt pl-[1rem]'>Viable Projects</span>
						</div>
						<div className='mt-[16px] flex items-center justify-between w-full'>
							<span className='home_card_txt'>KES 2,273,911,965</span>
							<span className='home_card_txt_count first-letter !text-[#11CB95]'>
								3
							</span>
						</div>
					</Link>

					<Link to='/' className='home_card'>
						<div className='flex items-center'>
							<svg
								stroke='currentColor'
								fill='currentColor'
								stroke-width='0'
								viewBox='0 0 512 512'
								color='#EA5453'
								height='24'
								width='24'
								xmlns='http://www.w3.org/2000/svg'
								style={{ color: 'rgb(234, 84, 83)' }}>
								<path d='M225 25v30h62V25h-62zm8 48v28.6l-5 2.5c-17 8.5-40.6 16.3-59.4 27.6-9.6 5.8-17.6 12-23.2 19.3h221.2c-5.6-7.3-13.6-13.5-23.2-19.3-18.8-11.3-42.4-19.1-59.4-27.6l-5-2.5V73h-46zm-112 96v16h270v-16H121zm16 34v28h238v-28H137zm-16 46v30h270v-30H121zm16 48v94h238v-94H137zm0 112v39c0 1 1.1 4.9 4 9.3 2.9 4.3 7.4 9.3 12.8 13.8 10.8 9 25.2 15.9 38.2 15.9h128c13 0 27.4-6.9 38.2-15.9 5.4-4.5 9.9-9.5 12.8-13.8 2.9-4.4 4-8.3 4-9.3v-39H137z'></path>
							</svg>

							<span className='home_header_txt pl-[1rem]'>
								Incomplete Projects
							</span>
						</div>
						<div className='mt-[16px] flex items-center justify-between w-full'>
							<span className='home_card_txt'>KES 98,273,911,965</span>
							<span className='home_card_txt_count !text-[#EA5453]'>33</span>
						</div>
					</Link>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-3 gap-x-[24px] w-full mt-[24px]'>
					<div className='home_card_2'>
						<div className='p-[10px] flex items-center w-full border-b border-[#e5e7eb]'>
							<svg
								stroke='currentColor'
								fill='currentColor'
								stroke-width='0'
								viewBox='0 0 512 512'
								height='28'
								width='28'
								xmlns='http://www.w3.org/2000/svg'>
								<path d='M253.625 18.406c-19.833 0-35.688 15.888-35.688 35.72 0 19.833 15.854 35.687 35.688 35.687 19.83 0 35.72-15.857 35.72-35.688 0-19.834-15.89-35.72-35.72-35.72zM93.97 65.25c-5.68 13.142-8.94 26.858-8.94 41.5 0 45.29 29.44 85.21 74.157 108.625L15.75 494.5h77.28l123.564-390.813-37.406 72.813c-50.628-20.564-85.22-61.99-85.22-109.75 0-.505-.007-.977 0-1.5zm315.405 0c.007.505 0 .977 0 1.5 0 46.98-33.468 87.856-82.75 108.75l-39.063-76 129.688 395h73.344L346.47 214.125c43.408-23.57 71.842-62.875 71.842-107.375 0-14.626-3.272-28.37-8.937-41.5zm-173.97 40.938L112.626 494.5h75.47l47.31-388.313zm36.032 4.156L318.25 494.5h79.313L271.438 110.344zm-18.28 4.5L206.906 494.5h92.5l-46.25-379.656z'></path>
							</svg>
							<span className='home_card_2_header_txt pl-[.5rem]'>
								Expression of Demand
							</span>
						</div>
						<div className='p-[10px] flex items-center justify-between w-full border-b border-[#e5e7eb]'>
							<span className='home_card_2_txt'>Completed Projects</span>
							<span className='home_card_2_txt pl-[.5rem]'>2</span>
						</div>
						<div className='p-[10px] flex items-center justify-between w-full border-b border-[#e5e7eb]'>
							<span className='home_card_2_txt'>Onboarding</span>
							<span className='home_card_2_txt pl-[.5rem]'>27</span>
						</div>
						<div className='p-[10px] flex items-center justify-between w-full border-b border-[#e5e7eb]'>
							<span className='home_card_2_txt'>Drafts</span>
							<span className='home_card_2_txt pl-[.5rem]'>6</span>
						</div>
					</div>

					<div className='home_card_2'>
						<div className='p-[10px] flex items-center w-full border-b border-[#e5e7eb]'>
							<svg
								stroke='currentColor'
								fill='currentColor'
								stroke-width='0'
								viewBox='0 0 640 512'
								height='28'
								width='28'
								xmlns='http://www.w3.org/2000/svg'>
								<path d='M488 192H336v56c0 39.7-32.3 72-72 72s-72-32.3-72-72V126.4l-64.9 39C107.8 176.9 96 197.8 96 220.2v47.3l-80 46.2C.7 322.5-4.6 342.1 4.3 357.4l80 138.6c8.8 15.3 28.4 20.5 43.7 11.7L231.4 448H368c35.3 0 64-28.7 64-64h16c17.7 0 32-14.3 32-32v-64h8c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24zm147.7-37.4L555.7 16C546.9.7 527.3-4.5 512 4.3L408.6 64H306.4c-12 0-23.7 3.4-33.9 9.7L239 94.6c-9.4 5.8-15 16.1-15 27.1V248c0 22.1 17.9 40 40 40s40-17.9 40-40v-88h184c30.9 0 56 25.1 56 56v28.5l80-46.2c15.3-8.9 20.5-28.4 11.7-43.7z'></path>
							</svg>
							<span className='home_card_2_header_txt pl-[.5rem]'>
								Pipelines
							</span>
						</div>
						<div className='p-[10px] flex items-center justify-between w-full border-b border-[#e5e7eb]'>
							<span className='home_card_2_txt'>Deal Sourcing</span>
							<span className='home_card_2_txt pl-[.5rem]'>0</span>
						</div>
						<div className='p-[10px] flex items-center justify-between w-full border-b border-[#e5e7eb]'>
							<span className='home_card_2_txt'>Project Preparations</span>
							<span className='home_card_2_txt pl-[.5rem]'>3</span>
						</div>
					</div>

					<div className='home_card_2'>
						<div className='p-[10px] flex items-center w-full border-b border-[#e5e7eb]'>
							<svg
								stroke='currentColor'
								fill='currentColor'
								stroke-width='0'
								version='1.2'
								baseProfile='tiny'
								viewBox='0 0 24 24'
								height='28'
								width='28'
								xmlns='http://www.w3.org/2000/svg'>
								<path d='M17 21h-8c-1.7 0-3-1.3-3-3v-12c0-1.7 1.3-3 3-3h8c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3zm-8-16c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-12c0-.6-.4-1-1-1h-8z'></path>
								<circle cx='10' cy='11' r='1'></circle>
								<circle cx='13' cy='11' r='1'></circle>
								<circle cx='16' cy='11' r='1'></circle>
								<circle cx='10' cy='14' r='1'></circle>
								<circle cx='13' cy='14' r='1'></circle>
								<circle cx='16' cy='14' r='1'></circle>
								<circle cx='10' cy='17' r='1'></circle>
								<circle cx='13' cy='17' r='1'></circle>
								<circle cx='16' cy='17' r='1'></circle>
								<path d='M16 7v1h-6v-1h6m1-1h-8v3h8v-3z'></path>
							</svg>
							<span className='home_card_2_header_txt pl-[.5rem]'>
								Deal Room
							</span>
						</div>
						<div className='p-[10px] flex items-center justify-between w-full border-b border-[#e5e7eb]'>
							<span className='home_card_2_txt'>Viable Projects</span>
							<span className='home_card_2_txt pl-[.5rem]'>1</span>
						</div>
						<div className='p-[10px] flex items-center justify-between w-full border-b border-[#e5e7eb]'>
							<span className='home_card_2_txt'>Offers</span>
							<span className='home_card_2_txt pl-[.5rem]'>0</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
