import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../../assets/svg/chevron-left.svg";
import { Form, Input, Select, InputNumber, Steps, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setBasicDetails } from "../../../../features/wsp_registration/wspRegSlice";
import ScrollToTop from "../../../../ScrollToTop";
import { instData } from "../../../../data";
import PhoneInput from "react-phone-input-2";
import AuthPartnerSideImage from "../../../../components/auth-partner/AuthPartnerSideImage";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;
export default function BasicDetailsPartnerStepper() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { basicDetails } = useSelector((state) => state.wspReg);

  function handleBack() {
    navigate("/");
  }

  const [data, setdata] = useState({});

  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");
  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf,.doc,.docx",
    action: `${url}/usr/postImages.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = async (values) => {
    await dispatch(setBasicDetails(values));
    await navigate("/signup-partner/core-indicators");
  };

  useEffect(() => {}, [basicDetails]);

  return (
    <>
      <ScrollToTop />
      <div className="lg:grid lg:grid-cols-6 p-3 lg:p-0">
        <div className="bg-[#226cbf] w-full col-span-2  hidden lg:block">
          <AuthPartnerSideImage
            hideStepper={false}
            hideText={false}
            current={0}
          />
        </div>
        <div className="w-full h-[100vh] lg:col-span-4">
          <div className="flex flex-col">
            <button
              onClick={handleBack}
              className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
             ml-[48px] items-center justify-center flex"
            >
              <img src={leftArrow} alt="backarrow" />
            </button>
            <div className="flex justify-center flex-col items-center">
              <img
               onClick={()=>navigate('/')}
                src={Logo}
                alt="logo"
                className="w-[211px] h-[73px] object-cover cursor-pointer"
              />
              <h3 className="signup-start mt-[3.13rem]">
                Fill in the following details
              </h3>
              <span className="signup-start-text  mt-[2.5rem]">
                Step 1 - Basic Details
              </span>

              <div className="mt-[2.25rem] px-6 w-fit">
                <Form
                  layout="vertical"
                  ref={formRef}
                  name="control-ref"
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                    width: "700px",
                    padding: 5,
                  }}
                  form={form}
                  initialValues={{
                    requiredMarkValue: false,
                    ...basicDetails,
                  }}
                  requiredMark={false}
                >
                  <Form.Item
                    extra="Business name as it appears on legal registration"
                    name="accTradeName"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input placeholder="Business name" className="reg-input" />
                  </Form.Item>

                  <Form.Item
                    extra={"Choose institution type"}
                    name="accType"
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please select type of institution",
                      },
                    ]}
                  >
                    <Select
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      // onChange={(value) => {
                      //   handleSelectChange(value, "accType");
                      // }}
                      options={
                        instData?.length > 0 &&
                        instData?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {}}
                      placeholder="Type of institution"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Business website"}
                    name="accWebsite"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please add official website",
                      },
                      {
                        type: "url",
                        message: "Please add a valid URL",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Organization website."
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Your business location"}
                    name="accLocation"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please add address",
                      },
                    ]}
                  >
                    <Input placeholder="Address" className="reg-input" />
                  </Form.Item>

                  <div className="partner-sub mb-[1rem]">Contact</div>

                  <Form.Item
                    extra={"For example, ‘Customer service’"}
                    name="usrTitle"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please add address",
                      },
                    ]}
                  >
                    <Input placeholder="Title" className="reg-input" />
                  </Form.Item>

                  <Form.Item
                    extra={"Name of contact person"}
                    name="usrFullNames"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please add full name",
                      },
                    ]}
                  >
                    <Input placeholder="Full name" className="reg-input" />
                  </Form.Item>

                  <Form.Item
                    extra={"For example 'yourname@email.com'"}
                    name="usrEmail"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please add email address",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Email address"
                      className="reg-input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={
                      "For example '+254 723 456 789' or ‘+254 110 123 456’ "
                    }
                    name="usrMobileNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please add phone no",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.length === 12) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Phone number must be 10 characters")
                          );
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      country="ke"
                      onlyCountries={["ke"]}
                      countryCodeEditable={false}
                      className="input-phone"
                    />
                  </Form.Item>

                  <div className="w-full flex justify-center">
                    <div className="w-[10.75rem]">
                      <button type="submit" className="nxt-btn !mt-[3.75rem]">
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              </div>

              <span className="already mt-[2.5rem]">
                Already have an account?{" "}
                <Link to="/login" className="already-text">
                  Log in
                </Link>
              </span>

              <div className="sticky mt-[5.75rem]">
                <span className="already-text !no-underline">
                  Privacy Policy • Terms and Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
