import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	loading: false,
	newProjects: [],
	projectDirectors: [],
	incompleteProjects: [],
};

export const fetchNewProjects = createAsyncThunk(
	'fetchSlice/fetchNewProjects',
	async () => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=null`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchProjectDirectors = createAsyncThunk(
	'fetchSlice/fetchProjectDirectors',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchDirectors.action?projId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchIncompleteProjects = createAsyncThunk(
	'fetchSlice/fetchIncompleteProjects',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=DRAFT`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchSlice = createSlice({
	name: 'fetch',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchIncompleteProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchIncompleteProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.incompleteProjects = action.payload;
			})
			.addCase(fetchIncompleteProjects.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectDirectors.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectDirectors.fulfilled, (state, action) => {
				state.loading = false;
				state.projectDirectors = action.payload;
			})
			.addCase(fetchProjectDirectors.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchNewProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchNewProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.newProjects = action.payload;
			})
			.addCase(fetchNewProjects.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default fetchSlice.reducer;
export const {} = fetchSlice.actions;
