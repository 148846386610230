import React, { useEffect, useRef, useState } from "react";
import svg1 from "../../../assets/svg/svg1.svg";
import svg2 from "../../../assets/svg/svg2.svg";
import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import toast from "react-hot-toast";
import { disabledDate, formatPath } from "../../../utils";
import moment from "moment";
import svg3 from "../../../assets/svg/svg3.svg";
import svg9 from "../../../assets/svg/svg9.svg";
import image3 from "../../../assets/img/image3.png";
import svg5 from "../../../assets/svg/svg5.svg";
import PhoneInput from "react-phone-input-2";
import { save, saveFile } from "../../../features/save/saveSlice";

function Auditor({ next, prev, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);

  const [formData, setFormData] = useState(wspApplications);

  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }

  const [fileUrlSheet, setfileUrlSheet] = useState(
    wspApplications?.projIncomeExpenditureFile ?? ""
  );
  async function handleCancelFileSheet() {
    await setfileUrlSheet();
  }
  async function handleUploadSheet(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlSheet(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlIncome, setfileUrlIncome] = useState(
    wspApplications?.projBankStatementFile ?? ""
  );
  async function handleCancelFileIncome() {
    await setfileUrlIncome();
  }
  async function handleUploadIncome(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlIncome(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projIncomeExpenditureFile: fileUrlSheet,
        projBankStatementFile: fileUrlIncome,
        projAuditorName: formData?.projAuditorName,
        projAuditorFirm: formData?.projAuditorFirm,
        projAuditorTelephone: formData?.projAuditorTelephone,
        projAuditorEmail: formData?.projAuditorEmail,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await next();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const handleNumberChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      projAuditorTelephone: String(e),
    }));
  };

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">d) Financial Information</div>

        <div className="profile_sub mt-[.94rem]">
          Summary of Financial Position
        </div>

        <div className="profile_sub mt-[2rem]">Most recent financial data</div>
        <div className="profile_sub mt-[.69rem]">
          Download the following sheet and fill it offline. You may upload the
          filled sheet in the next step.
        </div>

        <div className="w-full mt-[1.13rem]">
          <a
            href="https://fundmywater.com/myimages/Most_Recent_Month_Financial_Data.xlsx"
            style={{ textDecoration: "none" }}
            target="self"
          >
            <button
              type="button"
              className="download-btn flex items-center justify-between cursor-pointer"
            >
              <span className="flex justify-start">Download Data Sheet </span>
              <img src={svg9} alt="svg9" />
            </button>
          </a>
        </div>
      </div>

      <div className=" px-[3.88rem]">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          style={{
            maxWidth: "100%",
          }}
          className="w-[600px] mt-[1.13rem]"
          form={form}
        >
          <div className="flex flex-col">
            <Form.Item
              extra={"Upload file .xlsx"}
              rules={[
                {
                  required: false,
                  message: "Upload file .pdf",
                },
              ]}
              className="w-full"
              name={"gdcFileUrl"}
            >
              {fileUrlSheet ? (
                <>
                  <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                    <span className="text-[18px]">
                      {formatPath(fileUrlSheet)}
                    </span>

                    <button
                      type="button"
                      onClick={handleCancelFileSheet}
                      className="flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                          fill="#147CBC"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="input relative !bg-[#D1E9FF]"
                    onClick={handleOpenFIle}
                  >
                    <input
                      className="!hidden"
                      accept=".xlsx, .xls"
                      id="file"
                      name="file"
                      onChange={(e) => handleUploadSheet(e)}
                      type="file"
                    />
                    <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                      Upload Filled Data Sheet
                    </span>
                    <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                      <img src={uplooadSimple} alt="uploadSimple" />
                    </div>
                  </div>
                </>
              )}
            </Form.Item>

            <Form.Item
              extra={"Upload file .pdf"}
              rules={[
                {
                  required: false,
                  message: "Upload file .pdf",
                },
              ]}
              className="w-full"
              name={"fileUrlIncome"}
            >
              {fileUrlIncome ? (
                <>
                  <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                    <span className="text-[18px]">
                      {formatPath(fileUrlIncome)}
                    </span>

                    <button
                      type="button"
                      onClick={handleCancelFileIncome}
                      className="flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                          fill="#147CBC"
                        />
                      </svg>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="input relative" onClick={handleOpenFIle}>
                    <input
                      className="!hidden"
                      accept=".pdf"
                      id="file"
                      name="file"
                      onChange={(e) => handleUploadIncome(e)}
                      type="file"
                    />
                    <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                      Upload Income and expenditure for the last 12 months
                    </span>
                    <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                      <img src={uplooadSimple} alt="uploadSimple" />
                    </div>
                  </div>
                </>
              )}
            </Form.Item>

            <div className="profile_sub mt-[5.22rem] mb-[1.31rem]">
              Current auditor contact
            </div>

            <Form.Item
              extra={"Enter auditor’s full name"}
              className=""
              rules={[
                {
                  required: true,
                  message: "Add full name",
                },
              ]}
            >
              <Input
                name="projAuditorName"
                value={formData?.projAuditorName}
                onChange={onChange}
                placeholder="Full name"
                className="input"
              />
            </Form.Item>

            <Form.Item
              extra={"Enter auditor’s firm name"}
              className=""
              rules={[
                {
                  required: true,
                  message: "Add firm name",
                },
              ]}
            >
              <Input
                name="projAuditorFirm"
                value={formData?.projAuditorFirm}
                onChange={onChange}
                placeholder="Firm name"
                className="input"
              />
            </Form.Item>
            <Form.Item
              extra={"Enter auditor’s phone number"}
              rules={[
                {
                  required: true,
                  message: "Please add phone no",
                },
              ]}
            >
              <PhoneInput
                required
                placeholder="Telephone"
                name="projAuditorTelephone"
                onChange={handleNumberChange}
                value={formData?.projAuditorTelephone}
                country="ke"
                onlyCountries={["ke"]}
                countryCodeEditable={false}
                className="input-phone"
              />
            </Form.Item>

            <Form.Item
              extra={"Enter auditor’s email address"}
              rules={[
                {
                  required: true,
                  message: "Please add email address",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input
                name="projAuditorEmail"
                onChange={onChange}
                value={formData?.projAuditorEmail}
                type="email"
                className="input"
                placeholder="Email"
              />
            </Form.Item>

            <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
              <button type="submit" className="cstm-btn">
                {saving ? <Spin /> : "Continue"}
              </button>
              <button onClick={() => prev()} className="border-btn mt-[.75rem]">
                Previous
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Auditor;
