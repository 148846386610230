import React from 'react'
import KeyPerformanceIndicator from './KeyPerfomanceIndicator';
import OptimizationGoal from '../../dashboard/components/OptimizationGoal';
import ContributionPieChart from './ContributionPieChart';
import { capitalize } from '../../../../utils';



const WaterSectionTwo = ({ selected }) => {

    return (
        <div className='flex flex-row  mt-10  w-[100%] h-auto '>
            <div className='flex w-[70%] px-2 h-auto  mr-3 flex-col' >
                <div className='bg-white p-2'>
                    <div className='text-blk font-bold text-[1.125rem] mb-5'>{capitalize(selected)} Impact</div>
                    <div className='flex flex-row mb-10'>
                        <div className='text-blk mr-5'>Total Impact across {`${selected === "Total" ? "counties" : capitalize(selected)}`}</div>
                        <div className='flex ml-2 mr-2'>
                            {/* <div className='flex flex-row items-center'>
                                <div className='mt-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z" fill="#1C1C1C" />
                                    </svg>
                                </div>
                                <div>Target</div>
                            </div>
                            <div className='flex flex-row items-center ml-2'>
                                <div className='mt-1'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z" fill="#A8C5DA" />
                                </svg></div>

                                <div>Outcome</div>
                            </div> */}
                        </div>
                    </div>

                    <div className='h-[100%]'>
                        <KeyPerformanceIndicator selected={selected} />
                    </div>
                </div>
                <OptimizationGoal />
            </div>


            <div className='flex w-[30%] flex-col p-4 h-[100%] bg-white'>
                <div className='text-blk font-bold text-[1rem]'>Contribution summary</div>
                <ContributionPieChart countyCount />
            </div>

        </div>
    )
}

export default WaterSectionTwo