import { Col, Row } from "antd";
import userImage from "../../assets/img/userImage.png";
import arrowUp from "../../assets/svg/arrowUp.svg";
import arrowDown from "../../assets/svg/arrowDown.svg";

const yellowStar = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <g clip-path='url(#clip0_75_10219)'>
      <path
        d='M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z'
        fill='#FFD347'
      />
    </g>
    <defs>
      <clipPath id='clip0_75_10219'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default function WhatPeopleSay() {
  return (
    <>
      <div className='w-full flex flex-col bg-[#FCFCFC] mt-[5.13rem] py-[3.88rem] pl-[6.38rem] pr-[4.62rem] pb-[8rem]'>
        <span className='how_to_header_txt'>What people say</span>

        <Row className='mt-[2.63rem]'>
          <Col span={12}>
            <div className='flex flex-shrink-0'>
              <img src={userImage} alt='user' />
            </div>

            <div className='mt-[1.69rem] flex items-center gap-x-[.1rem]'>
              {yellowStar}
              {yellowStar}
              {yellowStar}
              {yellowStar}
              {yellowStar}
            </div>

            <div className='w-[30.0625rem] mt-[.94rem]'>
              <span className='user_comment_dark_txt'>
                "Through FundMyWater, we received two financing offers from
                banks and were able to compare and select a structure that was
                most convenient for us. The platform made the comparison process
                straightforward and transparent. Additionally, the financing was
                revenue-based and required no collateral, which was a
                significant advantage. The experts on the platform guided us
                through each step, ensuring we made the best decision for our
                utility. We are extremely satisfied with the outcome and the
                support we received."
              </span>
            </div>

            <div className='mt-[1.81rem] flex flex-col gap-y-[.25rem]'>
              <span className='user_comment_dark_txt'>John Kimani,</span>
              <span className='user_comment_sm_light_txt'>Maji Works.</span>
            </div>
          </Col>
          <Col span={12}>
            <div className='w-[30.0625rem]'>
              <span className='user_comment_dark_txt'>
                "FundMyWater not only provided us with excellent revenue-based
                financing options but also introduced us to Majibyte, an
                advanced billing and revenue system. This comprehensive solution
                has transformed our utility's operations, making billing more
                accurate and efficient. The financing required no collateral,
                and the experts on the platform were always available to assist
                us. This dual benefit of financing and improved operational
                tools has been invaluable to our business. We highly recommend
                FundMyWater to any utility looking to enhance their operations."
              </span>
            </div>

            <div className='mt-[1.81rem] flex flex-col gap-y-[.25rem]'>
              <span className='user_comment_dark_txt'>Pauline Mutheu,</span>
              <span className='user_comment_sm_light_txt'> Urban waters.</span>
            </div>

            <div className='w-full h-[0.0625rem] bg-[#E2E2E2] mt-[1.81rem] mb-[2.47rem]'></div>

            <div className='w-[30.0625rem]'>
              <span className='user_comment_dark_txt'>
                "FundMyWater has been a game-changer for our utility. The
                platform is incredibly convenient and accessible, allowing us to
                manage our financing needs with ease. The experts available on
                the site have been instrumental in helping us navigate the
                complexities of water utility financing. Their revenue-based
                financing options required no collateral, making the process
                smooth and hassle-free. Their tailored advice streamlined our
                operations and improved our overall efficiency. Highly
                recommend!"
              </span>
            </div>

            <div className='mt-[1.81rem] flex flex-col gap-y-[.25rem]'>
              <span className='user_comment_dark_txt'>Micheal Mudibo ,</span>
              <span className='user_comment_sm_light_txt'>
                Maji uhai Enterprises.
              </span>
            </div>

            <div className='w-full h-[0.0625rem] bg-[#E2E2E2] mt-[1.81rem] mb-[2.47rem]'></div>

            <div className='w-[30.0625rem]'>
              <span className='user_comment_light_txt'>
                "FundMyWater stands out for its comprehensive and user-friendly
                platform. It offers everything a water utility requires to
                manage financing and streamline operations. The expert guidance
                provided has been incredibly beneficial, helping us to refine
                our strategies and enhance our efficiency. Their revenue-based
                financing required no collateral, which was ideal for us. This
                platform is an essential tool for any water utility looking to
                improve its business processes."
              </span>
            </div>

            <div className='flex justify-between items-center'>
              <div className='mt-[1.81rem] flex flex-col gap-y-[.25rem]'>
                <span className='user_comment_light_txt'>Martin Wafula,</span>
                <span className='user_comment_sm_light_txt'>
                  Kimilili Hydro-suppliers.
                </span>
              </div>
              <div className='flex flex-col gap-y-[.9rem]'>
                <button>
                  <img src={arrowUp} alt='arrow up' />
                </button>

                <button>
                  <img src={arrowDown} alt='arrow down' />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
