import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import toast from "react-hot-toast";
import { disabledDate, formatPath } from "../../../utils";
import moment from "moment";
import svg10 from "../../../assets/svg/svg10.svg";
import svg6 from "../../../assets/svg/svg6.svg";
import image3 from "../../../assets/img/image3.png";
import svg4 from "../../../assets/svg/svg4.svg";
import { deleteBank, fetchBank } from "../../../features/bank/bankSlice";
import DeleteModal from "../../../components/DeleteModal";
import svg7 from "../../../assets/svg/svg7.svg";
import svg8 from "../../../assets/svg/svg8.svg";
import { save, saveFile } from "../../../features/save/saveSlice";

function Bank({ next, prev }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { bankData, loading } = useSelector((state) => state.bank);
  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);

  const [prodd, setProdd] = useState();
  const [formData, setFormData] = useState(prodd);
  useEffect(() => {
    setFormData(prodd);
  }, [prodd]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }

  

  
  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const [showAdd, setShowAdd] = useState(false);
  const handleAdd = async () => {
    await setShowAdd(true);
    await setProdd("");
  };

  const handleEdit = async (item) => {
    await setShowAdd(true);
  };

  const handleCancel = () => {
    setShowAdd(false);
  };
  const handleSave = async (data) => {
    const res = await dispatch(
      save({
        url: "/usr/saveBankInfo.action",
        bId: prodd?.bId ? prodd?.bId : null,
        bProjId: wspApplications?.projId,
        bName: formData?.bName,
        bBranch: formData?.bBranch,
        bAccNo: formData?.bAccNo,
        bAccName: formData?.bAccName,
        bFile: fileUrl,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res.payload?.messages?.message);
      await form.resetFields();
      await setfileUrl()
      await setFormData();
      fetchBankData();
      await setShowAdd(false);
    } else {
      await toast.error(res.payload?.messages?.message);
    }
  };
  async function fetchBankData() {
    dispatch(
      fetchBank({
        projId: wspApplications?.projId,
      })
    );
  }

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
  const content = `Are you sure you want to delete ${prodd?.bName}?`;

  const handleDelete = async () => {
    const res = await dispatch(
      deleteBank({
        bId: prodd?.bId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await setIsModalOpenDelete(false);
      await fetchBankData();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    fetchBankData();
  }, []);
 
  const [fileUrl, setfileUrl] = useState(formData?.bFile);
  useEffect(()=>{
    setfileUrl(formData?.bFile)
  },[formData?.bFile])
  async function handleCancelFile() {
    await setfileUrl();
  }
  async function handleUpload(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrl(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }
   
  
  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">d) Financial Information</div>

        <div className="profile_sub mt-[.94rem]">
          Add your bank account details. Be sure to provide information on all
          relevant bank accounts.
        </div>
      </div>
      {showAdd ? (
        <div className=" px-[3.88rem]">
          <div className="profile_sub mt-[.94rem]">
            Fill in your bank account details below
          </div>

          <div className="profile_sub mt-[1.5rem]">Bank account details</div>
          <div>
            <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={handleSave}
              style={{
                maxWidth: "100%",
              }}
              className="w-[600px] mt-[1.13rem]"
              form={form}
            >
              <div className="flex flex-col">
                <Form.Item
                  extra={"Enter bank name"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please add bank name",
                    },
                  ]}
                >
                  <Input
                    name="bName"
                    value={formData?.bName}
                    placeholder="Bank name"
                    onChange={onChange}
                    className="input"
                  />
                </Form.Item>
                <Form.Item
                  extra={"Enter bank branch"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please add bank branch",
                    },
                  ]}
                >
                  <Input
                    name="bBranch"
                    value={formData?.bBranch}
                    placeholder="Bank branch"
                    onChange={onChange}
                    className="input"
                  />
                </Form.Item>
                <Form.Item
                  extra={"Enter account number"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please add account number",
                    },
                  ]}
                >
                  <Input
                    name="bAccNo"
                    value={formData?.bAccNo}
                    placeholder="Account number"
                    onChange={onChange}
                    className="input"
                    type="number"
                  />
                </Form.Item>
                <Form.Item
                  extra={"Enter account name"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please add account name",
                    },
                  ]}
                >
                  <Input
                    name="bAccName"
                    value={formData?.bAccName}
                    placeholder="account name"
                    onChange={onChange}
                    className="input"
                  />
                </Form.Item>

                <Form.Item
                  extra={"Upload file .pdf"}
                  rules={[
                    {
                      required: false,
                      message: "Upload file .pdf",
                    },
                  ]}
                  name={"fileUrl"}
                >
                  {fileUrl ? (
                    <>
                      <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                        <span className="text-[18px]">
                          {formatPath(fileUrl)}
                        </span>

                        <button
                          type="button"
                          onClick={handleCancelFile}
                          className="flex items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                              fill="#147CBC"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input relative" onClick={handleOpenFIle}>
                        <input
                          className="!hidden"
                          accept=".pdf"
                          id="file"
                          name="file"
                          onChange={(e) => handleUpload(e)}
                          type="file"
                        />
                        <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                          PDF of bank statements for the last 12 months
                        </span>
                        <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                          <img src={uplooadSimple} alt="uploadSimple" />
                        </div>
                      </div>
                    </>
                  )}
                </Form.Item>

                <div className="mt-[2.9rem] mb-[4.56rem] flex gap-x-5 justify-end items-center text-center">
                  <button onClick={handleCancel} className="cancel-btn">
                    Cancel
                  </button>
                  <button type="submit" className="save-btn">
                    {saving ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <>
          {bankData && bankData?.length > 0 ? (
            <div className="px-[3.88rem] ">
              <div className="profile_sub mt-[.94rem]">
                Be sure to provide information on all relevant bank accounts.
              </div>
              <div className="flex items-center justify-between mt-[2.19rem]">
                <div className=" mngt-details-black">Bank Information</div>
                <div
                  className="mngt-details-blue flex items-center gap-x-2 cursor-pointer"
                  onClick={handleAdd}
                >
                  <img src={image3} alt="image3" />
                  Add Bank Account
                </div>
              </div>

              {bankData &&
                bankData?.map((item) => (
                  <>
                    <div
                      className="mt-[1.69rem] rounded-[.5rem] bg-white w-full p-[.75rem]"
                      onClick={() => setProdd(item)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="mngt-card-header"></div>
                        <div className="flex items-center gap-x-1 justify-end cursor-pointer">
                          <img
                            src={svg7}
                            alt="svg7"
                            onClick={() => handleEdit(item)}
                          />
                          <img
                            src={svg8}
                            alt="svg8"
                            onClick={showModalDelete}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-y-[.75rem] mt-2">
                        <div className="wtr-srce-title">Bank Name</div>
                        <div className="wtr-srce-content">{item?.bName}</div>

                        <div className="wtr-srce-title">Bank Branch</div>
                        <div className="wtr-srce-content">{item?.bBranch}</div>

                        <div className="wtr-srce-title">Acc No.</div>
                        <div className="mngt-card-file">{item?.bAccNo}</div>

                        <div className="wtr-srce-title">Acc Name</div>
                        <div className="wtr-srce-content">{item?.bAccName}</div>

                        <div className="wtr-srce-title">Statements</div>
                        <div className="mngt-card-file">
                          {formatPath(item?.bFile)}
                        </div>
                      </div>
                    </div>
                  </>
                ))}

              <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                <button onClick={next} className="cstm-btn">
                  Next
                </button>
                <button
                  onClick={() => prev()}
                  className="border-btn mt-[.75rem]"
                >
                  Previous
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="profile_sub mt-[.94rem] px-[3.88rem] ">
                Share your water sources below. You may add more than one
                source.
              </div>
              <div className="mt-[4.62rem] flex flex-col h-full text-center w-full items-center justify-center">
                <div className="w-[8.25rem] h-[8.25rem] rounded-full bg-[#D9EDFF] flex items-center justify-center">
                  <img src={svg10} alt="svg10" />
                </div>

                <div className="mngt-tool mt-[1.88rem]">Add Bank Account</div>
                <div className="mt-[1.5rem] flex flex-col">
                  <button
                    onClick={handleAdd}
                    className="cstm-btn flex items-center gap-x-1"
                  >
                    <img src={svg4} alt="svg4" />
                    Add
                  </button>
                  <button
                    onClick={() => prev()}
                    className="border-btn mt-[.75rem]"
                  >
                    Previous
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Delete Bank"
        loading={loading}
      />
    </>
  );
}

export default Bank;
