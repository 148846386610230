import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg6 from "../../../assets/svg/svg6.svg";
import image3 from "../../../assets/img/image3.png";
import svg4 from "../../../assets/svg/svg4.svg";
import DeleteModal from "../../../components/DeleteModal";
import { OperationalStaffData, TypeStaffData } from "../../../data";
import { deleteStaff, fetchStaff } from "../../../features/staff/staffSlice";
import { save } from "../../../features/save/saveSlice";
import { numberWithCommas } from "../../../utils";


function Staff({ prev, next, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { staffData, loading } = useSelector((state) => state.staff);
  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);

  const [prodd, setProdd] = useState();
  const [formData, setFormData] = useState(prodd);
  useEffect(() => {
    setFormData(prodd);
  }, [prodd]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const [showAdd, setShowAdd] = useState(false);
  const handleAdd = async () => {
    await setShowAdd(true);
    await setProdd("");
  };

  const handleEdit = async (item) => {
    await setShowAdd(true);
  };

  const handleCancel = () => {
    setShowAdd(false);
  };
  const handleSave = async (data) => {
    if (!formData?.osStaff) {
      toast.error("Please select operational staff");
      return;
    }
    if (!formData?.osContract) {
      toast.error("Please select contract");
      return;
    }
    const res = await dispatch(
      save({
        url: "/usr/saveOperationsStaff.action",
        osId: prodd?.osId ? prodd?.osId : null,
        osProjId: wspApplications?.projId,
        osStaff: formData?.osStaff,
        osNumber: formData?.osNumber,
        osContract: formData?.osContract,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res.payload?.messages?.message);
      await form.resetFields();
      await setFormData();
      fetchStaffData();
      await setShowAdd(false);
    } else {
      await toast.error(res.payload?.messages?.message);
    }
  };
  async function fetchStaffData() {
    dispatch(
      fetchStaff({
        projId: wspApplications?.projId,
      })
    );
  }

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
  const content = `Are you sure you want to delete ${prodd?.osStaff}?`;

  const handleDelete = async () => {
    const res = await dispatch(
      deleteStaff({
        osId: prodd?.osId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await setIsModalOpenDelete(false);
      await fetchStaffData();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const onChangeOther = (value, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    fetchStaffData();
  }, []);
 
  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">
          b) Project Description & Infrastructure
        </div>
      </div>

      {showAdd ? (
        <div className=" px-[3.88rem]">
          <div className="profile_sub mt-[.94rem]">Add Operations Staff</div>
          <div>
            <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={handleSave}
              style={{
                maxWidth: "100%",
              }}
              className="w-[600px] mt-[1.13rem]"
              form={form}
            >
              <div className="flex flex-col">
                <Form.Item
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select opertaional staff",
                    },
                  ]}
                >
                  <Select
                    name="osStaff"
                    value={formData?.osStaff}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "osStaff");
                    }}
                    placeholder="Type of Staff"
                    options={
                      OperationalStaffData?.length > 0 &&
                      OperationalStaffData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>

                <Form.Item
                  extra={"Enter number"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Add operation stuff number",
                    },
                  ]}
                >
                  <InputNumber
                  required
                    name="osNumber"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => onChangeOther(value, "osNumber")}
                    value={formData?.osNumber}
                    placeholder="Number of Operations Staff"
                    className="input"
                  />
                </Form.Item>

                <Form.Item
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select service ownership",
                    },
                  ]}
                >
                  <Select
                    name="osContract"
                    value={formData?.osContract}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "osContract");
                    }}
                    placeholder="Type of Contract"
                    options={
                      TypeStaffData?.length > 0 &&
                      TypeStaffData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>

                <div className="mt-[2.9rem] mb-[4.56rem] flex gap-x-5 justify-end items-center text-center">
                  <button onClick={handleCancel} className="cancel-btn">
                    Cancel
                  </button>
                  <button type="submit" className="save-btn">
                    {saving ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <>
          {staffData && staffData?.length > 0 ? (
            <div className="px-[3.88rem] ">
              <div className="profile_sub mt-[.94rem]">
                You may add multiple Operations Staff
              </div>
              <div className="flex items-center justify-between mt-[2.19rem]">
                <div className=" mngt-details-black">Operations Staff</div>
                <div
                  className="mngt-details-blue flex items-center gap-x-2 cursor-pointer"
                  onClick={handleAdd}
                >
                  <img src={image3} alt="image3" />
                  Add Operations Staff
                </div>
              </div>

              {staffData &&
                staffData?.map((item) => (
                  <div
                    className="mt-[1.69rem] rounded-[.5rem] bg-white w-full py-[.75rem] px-[1.31rem]"
                    onClick={() => setProdd(item)}
                  >
                    <div className="mngt-card-header">{item?.osStaff}</div>
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col gap-y-[.75rem]">
                        <div className="flex items-center  mt-2">
                          <div className="wtr-srce-title">
                            Number
                            <span className="wtr-srce-content ml-2">
                              {numberWithCommas(item?.osNumber)}
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center">
                          <div className="wtr-srce-title">
                            Contract
                            <span className="wtr-srce-content ml-2">
                              {item?.osContract}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-10 justify-end cursor-pointer">
                        <button
                          className="remove-blue"
                          onClick={showModalDelete}
                        >
                          Remove
                        </button>
                        <button className="remove-blue" onClick={handleEdit}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                <button onClick={() => nextStep()} className="cstm-btn">
                  Next
                </button>
                <button onClick={prev} className="border-btn mt-[.75rem]">
                  Previous
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="profile_sub mt-[.94rem] px-[3.88rem] ">
                Share your staff details below. You may add more several
                members.
              </div>
              <div className="mt-[4.62rem] flex flex-col h-full text-center w-full items-center justify-center">
                <img src={svg6} alt="svg6" />
                <div className="mngt-tool mt-[1.88rem]">
                  Add Operations Staff
                </div>
              </div>
              <div className="mt-[1.5rem] flex flex-col justify-center items-center w-full">
                <button
                  onClick={handleAdd}
                  className="cstm-btn flex items-center gap-x-1"
                >
                  <img src={svg4} alt="svg4" />
                  Add
                </button>
                <button onClick={prev} className="border-btn mt-[.75rem]">
                  Previous
                </button>
              </div>
            </div>
          )}
        </>
      )}

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Delete Staff"
        loading={loading}
      />
    </>
  );
}

export default Staff;
