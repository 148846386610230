import { Descriptions } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../../utils';
export default function ProjectDescriptionStep({ next, prev }) {
	const { newProjectObj } = useSelector((state) => state.obj);

	const { projDesc, projCost, projImpact } = newProjectObj;

	useEffect(() => {}, [newProjectObj]);

	const items = [
		{
			key: '1',
			label: 'Amount requested',
			children: formatMoney(projCost) ?? 'N/A',
		},
		{
			key: '2',
			label: 'Purpose of funds',
			children: projImpact ?? 'N/A',
		},
	];

	return (
		<>
			<div className='flex flex-col gap-y-[1.06rem]'>
				<div className='info_desc_card_2'>
					<div className='w-full'>
						<span className='ant-descriptions-title'>Project description</span>
					</div>

					<span className='ant-descriptions-item-content'>{projDesc}</span>
				</div>

				<div className='info_desc_card_2'>
					<Descriptions column={1} colon={false} title='Amount' items={items} />
				</div>
			</div>

			<div className='mt-[8.75rem] flex flex-col'>
				<button onClick={() => next()} className='cstm-btn'>
					Next
				</button>
				<button onClick={() => prev()} className='border-btn mt-[.75rem]'>
					Previous
				</button>
			</div>
		</>
	);
}
