import React from "react";
import svg1 from "../../../assets/svg/svg1.svg";
import svg2 from "../../../assets/svg/svg2.svg";
import { Checkbox } from "antd";

function ExpressionInterest({ next, prev }) {
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <>
      <div className="flex flex-col  h-full py-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">Expression of Interest</div>

        <div className="profile_sub mt-[1.56rem]">
          a. Governance Information
        </div>

        <div className="profile-card py-[2.13rem] px-[2.41rem] mt-[1.19rem] w-full">
          <div className="profile_sub flex items-center gap-x-1">
            <img src={svg2} alt="svg2" />
            To begin, please prepare the following documents
          </div>

          <div className="mt-[1.38rem] profile_p  flex flex-col gap-y-[1rem]">
            <Checkbox className="profile_p" onChange={onChange}>
              Statutory returns to registrar of years 2022, 2023, and 2024
            </Checkbox>
            <Checkbox className="profile_p" onChange={onChange}>
              Minutes of last AGM
            </Checkbox>
            <Checkbox className="profile_p" onChange={onChange}>
              PDF of constitution/bylaws or articles of association
            </Checkbox>
            <Checkbox className="profile_p" onChange={onChange}>
              PDF copy of minutes stating project willing to seek a loan
            </Checkbox>
            <Checkbox className="profile_p" onChange={onChange}>
              PDF copy of most recent & oldest committee meetings
            </Checkbox>
            <Checkbox className="profile_p" onChange={onChange}>
              PDF copy of past committee meetings
            </Checkbox>
            <Checkbox className="profile_p" onChange={onChange}>
              Marketing / Strategic / O&M plans / Customer contract
            </Checkbox>
          </div>

          <div className="mt-[3.19rem] flex flex-col justify-center items-center text-center">
            <button onClick={() => next()} className="cstm-btn">
              Continue
            </button>
            {/* <button onClick={() => prev()} className='border-btn mt-[.75rem]'>Previous</button>  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpressionInterest;
