import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg6 from "../../../assets/svg/svg6.svg";
import image3 from "../../../assets/img/image3.png";
import svg4 from "../../../assets/svg/svg4.svg";
import svg7 from "../../../assets/svg/svg7.svg";
import svg8 from "../../../assets/svg/svg8.svg";
import { save } from "../../../features/save/saveSlice";
import {
  deleteTotalStorage,
  fetchTotalStorages,
} from "../../../features/tank/tankSlice";
import DeleteModal from "../../../components/DeleteModal";
import { deleteWaterSource } from "../../../features/water-source/wtrSrcSlice";
import { MaterialData } from "../../../data";
import { numberWithCommas } from "../../../utils";

const items = [
  {
    title: "Tank A",
    material: "PVC",
    size: "1000m3",
  },
  {
    title: "Tank B",
    material: "PVC",
    size: "1000m3",
  },
];
function StorageTanks({ prev, next }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tankData, loading } = useSelector((state) => state.tank);
  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);

  const [prodd, setProdd] = useState();
  const [formData, setFormData] = useState(prodd);
  useEffect(() => {
    setFormData(prodd);
  }, [prodd]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }
  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const [showAdd, setShowAdd] = useState(false);
  const handleAdd = async () => {
    await setShowAdd(true);
    await setProdd("");
  };

  const handleEdit = async (item) => {
    await setShowAdd(true);
  };

  const handleCancel = () => {
    setShowAdd(false);
  };
  const handleSave = async (data) => {
    if (!formData?.stMaterial) {
      toast.error("Please select tank material");
      return;
    }
    const res = await dispatch(
      save({
        url: "/usr/saveTotalCapacityOfStorage.action",
        stId: prodd?.stId ? prodd?.stId : null,
        stProjId: wspApplications?.projId,
        stName: formData?.stName,
        stMaterial: formData?.stMaterial,
        stSize: formData?.stSize,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res.payload?.messages?.message);
      form.resetFields();
      setFormData();
      await fetchWaterStorageData();
      await setShowAdd(false);
    } else {
      await toast.error(res.payload?.messages?.message);
    }
  };
  async function fetchWaterStorageData() {
    dispatch(
      fetchTotalStorages({
        projId: wspApplications?.projId,
      })
    );
  }

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
  const content = `Are you sure you want to delete ${prodd?.stName}?`;

  const handleDelete = async () => {
    const res = await dispatch(
      deleteTotalStorage({
        stId: prodd?.stId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await setIsModalOpenDelete(false);
      await fetchWaterStorageData();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const onChangeOther = (value, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    fetchWaterStorageData();
  }, []);

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">
          b) Project Description & Infrastructure
        </div>
      </div>
      {showAdd ? (
        <div className=" px-[3.88rem]">
          <div className="profile_sub mt-[.94rem]">Add storage</div>
          <div>
            <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={handleSave}
              style={{
                maxWidth: "100%",
              }}
              className="w-[600px] mt-[1.13rem]"
              form={form}
            >
              <div className="flex flex-col">
                <Form.Item
                  extra={"Enter your tank name"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Add tank name",
                    },
                  ]}
                >
                  <Input
                    required
                    name="stName"
                    value={formData?.stName}
                    onChange={onChange}
                    placeholder="Tank name"
                    className="input"
                  />
                </Form.Item>

                <Form.Item
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select material",
                    },
                  ]}
                >
                  <Select
                    name="stMaterial"
                    value={formData?.stMaterial}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChange(value, "stMaterial");
                    }}
                    placeholder="Material"
                    options={
                      MaterialData?.length > 0 &&
                      MaterialData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>

                <Form.Item
                  extra={"Enter tank size"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Add tank size",
                    },
                  ]}
                >
                  <InputNumber
                    required
                    name="stSize"
                    value={formData?.stSize}
                    min={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => onChangeOther(value, "stSize")}
                    className="input"
                    placeholder="Size (m3)"
                  />
                </Form.Item>

                <div className="mt-[2.9rem] mb-[4.56rem] flex gap-x-5 justify-end items-center text-center">
                  <button onClick={handleCancel} className="cancel-btn">
                    Cancel
                  </button>
                  <button type="submit" className="save-btn">
                    {saving ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <>
          {tankData && tankData?.length > 0 ? (
            <div className="px-[3.88rem]">
              <div className="profile_sub mt-[.94rem]">
                You may add multiple storage tanks
              </div>
              <div className="flex items-center justify-between mt-[2.19rem]">
                <div className=" mngt-details-black">Storage Tanks</div>
                <div
                  className="mngt-details-blue flex items-center gap-x-2 cursor-pointer"
                  onClick={handleAdd}
                >
                  <img src={image3} alt="image3" />
                  Add Storage Tank
                </div>
              </div>

              {tankData &&
                tankData?.map((item) => (
                  <div
                    className="mt-[1.69rem] rounded-[.5rem] bg-white w-full p-[.75rem]"
                    onClick={() => setProdd(item)}
                  >
                    <div className="mngt-card-header">{item?.stName}</div>
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col gap-y-[.75rem] mt-2">
                        <div className="flex items-center gap-x-5">
                          <div className="wtr-srce-title">Material</div>
                          <div className="wtr-srce-content">
                            {item?.stMaterial}
                          </div>
                        </div>

                        <div className="flex justify-start items-center gap-x-5">
                          <div className="wtr-srce-title">Size</div>
                          <div className="wtr-srce-content">
                            {numberWithCommas(item?.stSize)}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-1 justify-end cursor-pointer">
                        <img src={svg7} alt="svg7" onClick={handleEdit} />
                        <img src={svg8} alt="svg8" onClick={showModalDelete} />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div>
              <div className="profile_sub mt-[.94rem] px-[3.88rem] ">
                Share your storage tank details below. You may add more than one
                tank.
              </div>
              <div className="mt-[4.62rem] flex flex-col h-full text-center w-full items-center justify-center">
                <img src={svg6} alt="svg6" />
                <div className="mngt-tool mt-[1.88rem]">Add storage tanks</div>
                <div className="mt-[1.5rem]">
                  <button
                    onClick={handleAdd}
                    className="cstm-btn flex items-center gap-x-1"
                  >
                    <img src={svg4} alt="svg4" />
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className="px-[3.88rem] ">
        <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
          <button onClick={next} className="cstm-btn">
            Next
          </button>
          <button onClick={prev} className="border-btn mt-[.75rem]">
            Previous
          </button>
        </div>
      </div>

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDelete}
        content={content}
        title="Delete Storage Tank"
        loading={loading}
      />
    </>
  );
}

export default StorageTanks;
