import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  staffData: [],
};
 
export const fetchStaff = createAsyncThunk(
  "staffSlice/fetchStaff",
  async (data) => {
    const response = await axios.get(
      `${url}/usr/fetchOperationsStaff.action?osProjId=${data?.projId}`
    ); 
    return response.data?.data?.result;
  }
);
export const deleteStaff = createAsyncThunk(
	"staffSlice/deleteStaff",
	async (data) => {
	  const response = await axios.get(
		`${url}/usr/deleteOperationsStaff.action?osId=${data?.osId}`
	  );  
	  return response.data;
	}
  );

  

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchStaff.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStaff.fulfilled, (state, action) => {
        state.loading = false;
        state.staffData = action.payload;
      })
      .addCase(fetchStaff.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteStaff.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteStaff.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default staffSlice.reducer;
export const {} = staffSlice.actions;
