import React from "react";
import waterImg from "../../assets/img/bgImg.png";
import { Steps } from "antd";

export default function AuthTaSideImage({ hideStepper, hideText, current }) {
  return (
    <>
      <div className="h-full w-full bgImg">
        {hideText ? null : (
          <div className="flex justify-center flex-col text-left px-[48px] w-full">
            <h3 className="signup-info mt-20 font-dmSans">Sign up as Technical Advisor</h3>
            <p className="signup-info-text mt-[1rem] font-dmSans">
            Create an account to get started
            </p>
             
          </div>
        )}

        {hideStepper ? null : (
          <>
            <Steps
              className="mt-[56px] px-[48px] relative"
              direction="vertical"
              current={current}
              items={[
                {
                  title: "Basic Details",
                },
                {
                  title: "Contact Person",
                }, 
                {
                  title: "Password Setup",
                },
              ]}
            />
          </>
        )}

        <div className="">
          <img
            className={
              hideStepper
                ? "absolute bottom-0 h-[400px] w-full object-fill"
                : "h-[100vh] w-full object-cover bg-[#226cbf]"
            }
            src={waterImg}
            alt="water-background"
          />
        </div>
      </div>
    </>
  );
}
