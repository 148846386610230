import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Table } from "antd";
import { save } from "../../../../features/save/saveSlice";
import { useDispatch, useSelector } from "react-redux";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [editable, dataIndex, record, form]);

  const saveN = async () => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            validator: (_, value) =>
              value >= 0
                ? Promise.resolve()
                : Promise.reject(new Error(`${title} must be non-negative.`)),
          },
        ]}
      >
        <InputNumber
          min={0}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          className="input"
          ref={inputRef}
          onPressEnter={saveN}
          onBlur={saveN}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const VolumetricTable = () => {
  const dispatch = useDispatch();
  const { wspApplications } = useSelector((state) => state.wsp);
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      name: "Individual",
      vol1: wspApplications?.projIndV1,
      vol2: wspApplications?.projIndV2,
      charge: wspApplications?.projIndividualCharge,
    },
    {
      key: "2",
      name: "Kiosk",
      vol1: wspApplications?.projKioskV1,
      vol2: wspApplications?.projKioskV2,
      charge: wspApplications?.projKioskCharge,
    },
    {
      key: "3",
      name: "Institution (School, HCF)",
      vol1: wspApplications?.projInstV1,
      vol2: wspApplications?.projInstV2,
      charge: wspApplications?.projIntitutionCharge,
    },
    {
      key: "4",
      name: "Commercial",
      vol1: wspApplications?.projComV1,
      vol2: wspApplications?.projComV2,
      charge: wspApplications?.projCommercialCharge,
    },
    {
      key: "5",
      name: "Other",
      vol1: wspApplications?.projOtherV1,
      vol2: wspApplications?.projOtherV2,
      charge: wspApplications?.projOtherCharge,
    },
  ]);

  const defaultColumns = [
    {
      title: "Customer Type",
      dataIndex: "name",
      editable: false,
    },
    {
      title: "Min Vol(1)",
      dataIndex: "vol1",
      editable: true,
    },
    {
      title: "Min Vol(2)",
      dataIndex: "vol2",
      editable: true,
    },
    {
      title: (
        <>
          Min Charge Ksh/m<sup>3</sup>
        </>
      ),
      dataIndex: "charge",
      editable: true,
    },
  ];

  const handleSave = async (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    console.log("DataSource after save:", newData);
    const res = await dispatch(
      save({
        projIndV1: newData?.[0]?.vol1,
        projIndV2: newData?.[0]?.vol2,
        projIndividualCharge: newData?.[0]?.charge,

        projKioskV1: newData?.[1]?.vol1,
        projKioskV2: newData?.[1]?.vol2,
        projKioskCharge: newData?.[1]?.charge,

        projInstV1: newData?.[2]?.vol1,
        projInstV2: newData?.[2]?.vol2,
        projIntitutionCharge: newData?.[2]?.charge,

        projComV1: newData?.[3]?.vol1,
        projComV2: newData?.[3]?.vol2,
        projCommercialCharge: newData?.[3]?.charge,

        projOtherV1: newData?.[4]?.vol1,
        projOtherV2: newData?.[4]?.vol2,
        projOtherCharge: newData?.[4]?.charge,

        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default VolumetricTable;
