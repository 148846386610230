import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Radio, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg6 from "../../../assets/svg/svg6.svg";
import image3 from "../../../assets/img/image3.png";
import svg4 from "../../../assets/svg/svg4.svg";
import svg7 from "../../../assets/svg/svg7.svg";
import svg8 from "../../../assets/svg/svg8.svg";
import { formatPath } from "../../../utils";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import { save, saveFile } from "../../../features/save/saveSlice";
import { InstitutionTypeData } from "../../../data";
import { fetchInst } from "../../../features/wsp/wspSlice";

function ConnectionType({ prev, next, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wspApplications, instConnection } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);

  const [fileUrl, setfileUrl] = useState("");
  const [formData, setFormData] = useState(wspApplications);

  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  const [formDataInst, setFormDataInst] = useState(instConnection);

  useEffect(() => {
    setFormDataInst(instConnection);
  }, [instConnection]);

  function handleSelectChangeInst(value, formName) {
    setFormDataInst((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }
  async function handleOpenFIle2() {
    document.getElementById("file2").click();
  }
  async function handleCancelFile() {
    await setfileUrl();
  }

  async function handleUpload(e) {
    // const formObject = new FormData();
    // formObject.append("file", e.target.files[0]);
    // const res = await dispatch(saveFile(formObject));
    // console.log("res", res);
    // if (res?.payload?.success) {
    //   setfileUrl(res?.payload?.data?.result);
    // } else {
    //   toast.error(
    //     res?.payload?.messages?.error ??
    //       "There was an error uploading this file"
    //   );
    // }
  }

  const [value, setValue] = useState(
    wspApplications?.projInstitutionCommercialConn
  );

  const onChangeRadio = async (e) => {
    setValue(e.target.value);
    const res = await dispatch(
      save({
        projInstitutionCommercialConn: e.target.value,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
  };

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const onChangeInst = async (e) => {
    setFormDataInst((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [fileUrlInst, setfileUrlInst] = useState(
    instConnection?.instAttachment ?? ""
  );
  async function handleCancelFileInst() {
    await setfileUrlInst();
  }
  async function handleUploadInst(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlInst(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlInst2, setfileUrlInst2] = useState(
    wspApplications?.projInstConnFile ?? ""
  );
  async function handleCancelFileInst2() {
    await setfileUrlInst2();
  }
  async function handleUploadInst2(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlInst2(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlKiosk, setfileUrlKiosk] = useState(
    wspApplications?.projKioskConnFile ?? ""
  );
  async function handleCancelFileKiosk() {
    await setfileUrlKiosk();
  }
  async function handleUploadKiosk(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlKiosk(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const onFinishInstitution = async () => {
    const res = await dispatch(
      save({
        instId: formDataInst?.instId ? formDataInst?.instId : null,
        instName: formDataInst?.instName,
        instNo: formDataInst?.instNo,
        instAttachment: fileUrlInst,
        url: "/usr/saveInstitute.action",
        instProjId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await fetchInstData();
      await next();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchInstData() {
    dispatch(
      fetchInst({
        projId: wspApplications?.projId,
      })
    );
  }

  useEffect(() => {
    fetchInstData();
  }, []);

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projNumberWaterKiosks: formData?.projNumberWaterKiosks,
        projNumberHouseholdsServed: formData?.projNumberHouseholdsServed,
        projAproxPopulationServed: formData?.projAproxPopulationServed,
        projTotalPopulation: formData?.projTotalPopulation,
        projArea: formData?.projArea,
        projInstConnFile: fileUrlInst2,
        projKioskConnFile: fileUrlKiosk,

        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await next();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };


  const onChangeOtherI = (value, fieldName) => {  
    setFormDataInst((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const onChangeOther = (value, fieldName) => {  
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">c) Customer Information</div>
      </div>

      <div>
        <div className="mngt-tool mt-[.94rem] px-[3.88rem] flex flex-col">
          Choose Connection Type
          <div className="mt-[.94rem]">
            <Radio.Group onChange={onChangeRadio} value={value}>
              <Radio className="mngt-tool" value={"Institution"}>
                Institution Connections
              </Radio>
              <Radio className="mngt-tool" value={"Commercial"}>
                Commercial Connection
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </div>

      {value === "Institution" && (
        <div className=" px-[3.88rem]">
          <div className="mngt-tool mt-[.94rem]">
            You may add as many institution connections as you like
          </div>
          <div>
            <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={onFinishInstitution}
              style={{
                maxWidth: "100%",
              }}
              className="w-[600px] mt-[1.13rem]"
              form={form}
            >
              <div className="flex flex-col">
                <Form.Item
                  extra={"Choose Option"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Please select service ownership",
                    },
                  ]}
                >
                  <Select
                    name="instName"
                    value={formDataInst?.instName}
                    className=""
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleSelectChangeInst(value, "instName");
                    }}
                    placeholder="Type of institution"
                    options={
                      InstitutionTypeData?.length > 0 &&
                      InstitutionTypeData?.map((item) => ({
                        value: item?.value,
                        label: item?.label,
                      }))
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLocaleLowerCase() ?? "").includes(
                        input?.toLocaleLowerCase()
                      )
                    }
                    onDropdownVisibleChange={() => {
                      // fetchResellerData();
                    }}
                  />
                </Form.Item>

                <Form.Item
                  extra={"Enter number of institutions"}
                  className=""
                  rules={[
                    {
                      required: true,
                      message: "Add number of institutions",
                    },
                  ]}
                >
                  <InputNumber
                    name="instNo"
                    value={formDataInst?.instNo} 
                    min={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => onChangeOtherI(value, "instNo")}
                    placeholder="Number of institutions"
                    className="input"
                  />
                </Form.Item>

                <Form.Item
                  extra={"Upload .pdf"}
                  rules={[
                    {
                      required: false,
                      message: "Upload file .pdf",
                    },
                  ]}
                  name={"fileUrlInst"}
                >
                  {fileUrlInst ? (
                    <>
                      <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                        <span className="text-[18px]">
                          {formatPath(fileUrlInst)}
                        </span>

                        <button
                          type="button"
                          onClick={handleCancelFileInst}
                          className="flex items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                              fill="#147CBC"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input relative" onClick={handleOpenFIle}>
                        <input
                          className="!hidden"
                          accept=".pdf"
                          id="file"
                          name="file"
                          onChange={(e) => handleUploadInst(e)}
                          type="file"
                        />
                        <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                          Institution attachment
                        </span>
                        <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                          <img src={uplooadSimple} alt="uploadSimple" />
                        </div>
                      </div>
                    </>
                  )}
                </Form.Item>

                <div className="mt-[2.9rem] mb-[4.56rem] flex gap-x-5 justify-end items-center text-center">
                  <button type="submit" className="save-btn">
                    {saving ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}

      {value === "Commercial" && (
        <>
          <div className="px-[3.88rem]">
            <div>
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: "100%",
                }}
                className="w-[600px] mt-[1.13rem]"
                form={form}
              >
                <div className="flex flex-col">
                  <Form.Item
                    extra={"Enter number of water kiosks"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Add number of water kiosks",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projNumberWaterKiosks"
                      value={formData?.projNumberWaterKiosks}
                      min={0}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => onChangeOther(value, "projNumberWaterKiosks")} 
                      placeholder="Number of water kiosks"
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter number of households served"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Add number of households served",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projNumberHouseholdsServed"
                      value={formData?.projNumberHouseholdsServed}
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) => onChangeOther(value, "projNumberHouseholdsServed")} 
                      placeholder="Number of households served"
                      className="input"
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Enter approximate population served"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Add approximate population served",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projAproxPopulationServed"
                      value={formData?.projAproxPopulationServed}
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) => onChangeOther(value, "projAproxPopulationServed")} 
                      placeholder="Approximate population served"
                      className="input"
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Enter total population in service area"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "Add approximate total population in service area",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projTotalPopulation"
                      value={formData?.projTotalPopulation}
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) => onChangeOther(value, "projTotalPopulation")} 
                      placeholder="Approximate total population in service area"
                      className="input"
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Enter service provision area (km2)"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Add service provision area (km2)",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projArea"
                      value={formData?.projArea}
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) => onChangeOther(value, "projArea")} 
                      placeholder="Service provision area (km2)"
                      className="input"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Upload .pdf"}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlInst2"}
                  >
                    {fileUrlInst2 ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlInst2)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileInst2}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file"
                            name="file"
                            onChange={(e) => handleUploadInst2(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF of institution sample customer contract
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={"Upload .pdf"}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlKiosk"}
                  >
                    {fileUrlKiosk ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlKiosk)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileInst}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle2}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file2"
                            name="file"
                            onChange={(e) => handleUploadKiosk(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF of kiosk sample customer contract
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <div className="mt-[3.95rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                    <button type="submit" className="cstm-btn">
                      {saving ? <Spin /> : "Next"}
                    </button>
                    <button
                      onClick={() => prev()}
                      className="border-btn mt-[.75rem]"
                    >
                      Previous
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ConnectionType;
