import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  authLoading: false,
  user: {},
  isActive: false,
  token: "",
  isLoggedIn: false,
  allUsers: [],
  forgotValueEmail:''
};

export const login = createAsyncThunk("authSlice/login", async (data) => {
  const res = await axios
    .post(`${url}/usr/post_authenticate_user.action`, data)
    .then((res) => res.data);
  return res;
});

export const updateUser = createAsyncThunk(
  "authSlice/updateUser",
  async (data) => {
    const res = await axiosInstance
      .post(`${url}/auth/saveUser.action`, data)
      .then((res) => res.data);
    return res;
  }
);

export const fetchCurrentUser = createAsyncThunk(
  "authSlice/fetchCurrentUser",
  async ({ accId, usrId }) => {
    const res = await axiosInstance
      .get(
        `${url}/usr/fetchUsers.action?accId=${accId ?? ""}&usrId=${usrId ?? ""}`
      )
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const fetchAllUser = createAsyncThunk(
  "authSlice/fetchAllUser",
  async ({ accId, usrId }) => {
    const res = await axiosInstance
      .get(
        `${url}/usr/fetchUsers.action?accId=${accId ?? ""}&usrId=${usrId ?? ""}`
      )
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const deleteUser = createAsyncThunk(
  "authSlice/deleteUser",
  async (usrId) => {
    const res = await axiosInstance
      .get(`${url}/usr/deleteUser.action?usrId=${usrId}`)
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const addUser = createAsyncThunk(
  "authSlice/addUser",
  async (newUser) => {
    const res = await axiosInstance
      .post(`${url}/usr/saveUser.action`, newUser)
      .then((res) => {
        if (!res.data.success) {
          return Promise.reject(res.data.message);
        }
        return res.data.data.result;
      });
    return res;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = {};
      state.isActive = false;
      state.isLoggedIn = false;
      state.token = "";
      localStorage.clear(); 
    },
    setAuth: (state, action) => {
      state.token = action.payload.token;
      state.isActive = true;
      state.user = action.payload.user;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setForgotValueEmail: (state, action) => {
      state.forgotValueEmail = action.payload;
    }, 
    cleanForgotValueEmail: (state, action) => {
      state.forgotValueEmail = initialState.forgotValueEmail;
    }, 
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(updateUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.authLoading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(login.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.authLoading = false;
        state.user = action?.payload?.jsonData;
      })
      .addCase(login.rejected, (state) => {
        state.authLoading = false;
        state.user = {};
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.authLoading = false;
        state.isActive = true;
        state.user = action.payload[0];
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.authLoading = false;
      })
      .addCase(fetchAllUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchAllUser.fulfilled, (state, action) => {
        state.authLoading = false;
        state.isActive = true;
        state.allUsers = action.payload;
      })
      .addCase(fetchAllUser.rejected, (state, action) => {
        state.authLoading = false;
      });
  },
});

export default authSlice.reducer;
export const { logout, setAuth, setIsLoggedIn,setForgotValueEmail,cleanForgotValueEmail } = authSlice.actions;
