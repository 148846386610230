import PageHeader from "../../components/PageHeader";
import BreadCrumb from "../../layout/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Steps } from "antd";
import ExpressionInterest from "./steps/ExpressionInterest";
import RegistrationReview from "./steps/RegistrationReview";
import GovernanceDocuments from "./steps/GovernanceDocuments";
import MinutesDocuments from "./steps/MinutesDocuments";
import ProjectDescription from "./steps/ProjectDescription";
import CustomerInformation from "./steps/CustomerInformation";
import FinancialPosition from "./steps/FinancialPosition";
import ProjectDetails from "./steps/ProjectDetails";
import { fetchWspProject } from "../../features/wsp/wspSlice";

export default function OnboardWsp() {
  const { newProjectObj } = useSelector((state) => state.obj);
  const { user } = useSelector((state) => state.auth);
  const { wspApplications } = useSelector((state) => state.wsp);

  const dispatch = useDispatch();

  const [current, setCurrent] = useState(0);

  const next = async () => {
    await setCurrent(current + 1);
    await fetchWspProjectData();
  };

  const prev = async () => {
    await setCurrent(current - 1);
    await fetchWspProjectData();
  };

  const onChange = async(value) => {
    if(wspApplications?.projStatus === "DRAFT"){
      await setCurrent(value);
      await fetchWspProjectData()
    }
    
  };

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  useEffect(() => {
    fetchWspProjectData();
  }, [user]);

  const steps = [
    {
      key: 0,
      title:
        wspApplications?.projStatus === "ACTIVE"
          ? "Registration Review"
          : "Business Profile",
      content: (
        <RegistrationReview next={next} wspApplications={wspApplications} />
      ),
    },
    {
      key: 1,
      title: "Expression of Interest",
      content: (
        <ExpressionInterest
          next={next}
          prev={prev}
          wspApplications={wspApplications}
        />
      ),
    },
    {
      key: 2,
      title: "Governance Information",
      content: (
        <GovernanceDocuments
          next={next}
          prev={prev}
          wspApplications={wspApplications}
        />
      ),
    },
    {
      key: 3,
      title: "Project Description & Infrastructure",
      content: (
        <ProjectDescription
          next={next}
          prev={prev}
          wspApplications={wspApplications}
        />
      ),
    },
    {
      key: 4,
      title: "Customer Information",
      content: (
        <CustomerInformation
          next={next}
          prev={prev}
          wspApplications={wspApplications}
        />
      ),
    },
    {
      key: 5,
      title: "Financial Information",
      content: (
        <FinancialPosition
          next={next}
          prev={prev}
          wspApplications={wspApplications}
        />
      ),
    },
    {
      key: 5,
      title: "Project Details",
      content: (
        <ProjectDetails
          next={next}
          prev={prev}
          wspApplications={wspApplications}
          current={current} 
          setCurrent={setCurrent}
        />
      ),
    },
  ];
  
  
  const filteredSteps =
    wspApplications?.projStatus === "ACTIVE" ? [steps[0]] : steps;

  const items = filteredSteps?.map((item) => ({
    key: item.key,
    title: item.title,
  }));

 

  
  return (
    <>
      <div className="w-full flex flex-col">
        <div className="heading_1 ">Welcome, {user?.usrFullNames}.</div>

        <div className="heading_sub mt-[1.31rem]">
          Here’s your application status
        </div>

        <div className="gridx grid grid-cols-2 items-start mt-[3.19rem] h-full">
          <div className="new_project_steps_card  w-full">
            <span className="step_header_txt">
              {wspApplications?.accTradeName}
            </span>
            <span className="step_desc_txt mt-[.58rem]">
              Fill in the following section to complete your application.
            </span>
            <Steps 
              onChange={onChange}
              direction="vertical"
              current={current}
              items={items}
              className="mt-[2.23rem]"
            />
          </div>
          <div className="w-full new_project_info_card h-full">
            {steps?.[current]?.content}
          </div>
        </div>

    
      </div>
    </>
  );
}
