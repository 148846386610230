import { Progress } from "antd";

const DoubleBar = ({ target, output, percent, title, svgIcon }) => {
    const conicColors = {
        '0%': '#0170BD',
        '25%': '#0170BD',
        '50%': '#0873B9',
        '100%': '#DEEFFF',
    };
    return (
        <div className="flex flex-col w-[80%]">
            <div className='flex flex-row ml-2 my-4'>
                <div className='w-[100%]'>
                    <div className="flex flex-row justify-between w-[70%]">
                        <span className="text-blk font-bold text-[0.75rem]">{title}</span>
                    </div>
                    <Progress
                        percent={percent}
                        strokeColor={conicColors}
                        showInfo={false}
                        trailColor={"#DEEFFF"}
                        success={{
                            percent: output,
                        }}
                    />
                    <div className="flex flex-row justify-between w-[70%]">
                        <span className="text-[0.75rem]">{`current ${output}%`}</span>
                        <span className="text-[0.75rem]">{`target: ${target}%`}</span>
                    </div>
                </div>
                <div className='flex flex-col ml-4'>
                    <div>
                        {svgIcon}
                    </div>
                    <div className='flex flex-row text-blk text-[0.875rem] font-[600]'>
                        {output}
                        <div>%</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoubleBar