import { Link } from 'react-router-dom';
import seedlings from '../../assets/img/seedlings.png';

export default function NeedWater() {
	return (
		<>
			<div className='flex items-start h-[23.3125rem] w-full mt-[3rem]'>
				<div className='flex flex-shrink-0 h-full'>
					<img  className='object-fill' src={seedlings} alt='seedlings' />
				</div>
				<div className='flex w-full flex-col bg-[#0170BD] px-[5.63rem] py-[6.5rem]'>
					<span className='need_water_header_txt'>
						Need water infrastructure in your area?
					</span>

					<span className='need_water_txt mt-[1.62rem]'>
						Fund my water is here to meet your financial needs
					</span>

					<Link
						to='/signup-wsp/getting-started'
						className='cstm-btn mt-[2.13rem] !bg-white !text-[#0170BD]'>
						Let’s get started
					</Link>
				</div>
			</div>
		</>
	);
}
