import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	wspLoading: false,
	error: '',
	basicDetails: {},
	businessDetails: {},
	managementDetails: [],
	projectDescription: {}, 

	contactDetails: {},

	coreIndicator: [],
	outcome:[],
	mandate:{},
};

export const saveWspRegistration = createAsyncThunk('wspRegSlice/saveWspRegistration', (data) => {
	return axios
		.post(
			`${url}/usr/create_account.action`,data,
		)
		.then((res) => res.data);
});

export const saveWspBusiness = createAsyncThunk('wspRegSlice/saveWspBusiness', (data) => {
	return axios
		.post(
			`${url}/usr/save_account.action`,data,
		)
		.then((res) => res.data);
});

export const saveWspDirector = createAsyncThunk('wspRegSlice/saveWspDirector', (data) => {
	return axios
		.post(
			`${url}/usr/saveDirector.action`,data,
		)
		.then((res) => res.data);
});

export const saveWspProject = createAsyncThunk('wspRegSlice/saveWspProject', (data) => {
	return axios
		.post(
			`${url}/usr/save_project.action`,data,
		)
		.then((res) => res.data);
});

 

 

const wspRegSlice = createSlice({
	name: 'wspReg',
	initialState,
	reducers: {
		reset: (state) => {
			state.basicDetails = {};
			state.businessDetails = {};
			state.managementDetails = [];
			state.projectDescription = {};
			state.contactDetails = {};
			state.coreIndicator = [];
			state.outcome = [];
			state.mandate = {};
		},

		setBasicDetails: (state, action) => {
			state.basicDetails = action.payload;
		},
		 
		setWspPassword: (state, action) => {
			state.basicDetails = {
				...state.basicDetails,
				usrEncryptedPassword: action.payload,
			};
		},

		setBusinessDetails: (state, action) => {
			state.businessDetails = action.payload;
		},

		setManagementDetails: (state, action) => {
			state.managementDetails = action.payload;;
		},

		setProjectDescription: (state, action) => {
			state.projectDescription = action.payload;
		},
		 
		setContactDetails: (state, action) => {
			state.contactDetails = action.payload;
		},
		setIndicatorsDetails: (state, action) => {
			state.coreIndicator = action.payload;
		},
		setOutcomeDetails: (state, action) => {
			state.outcome = action.payload;
		},
		setMandateDetails: (state, action) => {
			state.mandate = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder

			.addCase(saveWspRegistration.pending, (state) => {
				state.wspLoading = true;
			})
			.addCase(saveWspRegistration.fulfilled, (state) => {
				state.wspLoading = false;
			})
			.addCase(saveWspRegistration.rejected, (state) => {
				state.wspLoading = false;
			})

			.addCase(saveWspBusiness.pending, (state) => {
				state.wspLoading = true;
			})
			.addCase(saveWspBusiness.fulfilled, (state) => {
				state.wspLoading = false;
			})
			.addCase(saveWspBusiness.rejected, (state) => {
				state.wspLoading = false;
			})

			.addCase(saveWspDirector.pending, (state) => {
				state.wspLoading = true;
			})
			.addCase(saveWspDirector.fulfilled, (state) => {
				state.wspLoading = false;
			})
			.addCase(saveWspDirector.rejected, (state) => {
				state.wspLoading = false;
			})

			.addCase(saveWspProject.pending, (state) => {
				state.wspLoading = true;
			})
			.addCase(saveWspProject.fulfilled, (state) => {
				state.wspLoading = false;
			})
			.addCase(saveWspProject.rejected, (state) => {
				state.wspLoading = false;
			})

			
	},
});

export default wspRegSlice.reducer;
export const {
	reset,
	setBasicDetails,
	setBusinessDetails,
	setManagementDetails,
	setWspPassword,
	setProjectDescription,
	setContactDetails,
	setIndicatorsDetails,
	setOutcomeDetails,
	setMandateDetails
} = wspRegSlice.actions;
