import { createHashRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Root from "./Root";
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import ImpactDashboard from "./pages/impact-dashbaord/dashboard/ImpactDashboard";
import WaterResource from "./pages/impact-dashbaord/water-resource/WaterResource";
import Monitoring from "./pages/impact-dashbaord/monitoring/Monitoring";

import SignupWsp from "./pages/auth/signup_wsp/SignupWsp";
import BasicDetailsStepper from "./pages/auth/signup_wsp/signup_stepper/BasicDetails";
import BusinessDetailsStepper from "./pages/auth/signup_wsp/signup_stepper/BusinessDetails";
import ManagementDetails from "./pages/auth/signup_wsp/signup_stepper/ManagementDetails";
// import ProjectDescription from "./pages/auth/signup_wsp/components/ProjectDescription";
import PasswordField from "./pages/auth/signup_wsp/signup_stepper/PasswordField";
import ViewNewProject from "./pages/new_projects/ViewNewProject";
import ApproveWsp from "./pages/approve-wsp-registration/ApproveWsp";
import OnboardWsp from "./pages/onboard_wsp/OnboardWsp";
import Login from "./pages/auth/signin/Login";
import SingleWaterResource from "./pages/impact-dashbaord/water-resource/SingleWaterResource";
import LoanOffersList from "./pages/loan_offers/LoanOffersList";
import LoanOfferView from "./pages/loan_offers/LoanOfferView";
import InvoiceList from "./pages/project-development/InvoiceList";
import InvoiceView from "./pages/project-development/InvoiceView";
import ProjectDevelopmentWsp from "./pages/project-development/ProjectDevelopmentWsp";
import Timelines from "./pages/timeline/Timelines";
import Account from "./pages/auth/account/Account";
import BasicDetailsTaStepper from "./pages/auth/signup_ta/signup_stepper/BasicDetails";
import ContactDetails from "./pages/auth/signup_ta/signup_stepper/ContactDetails";
import PasswordTaField from "./pages/auth/signup_ta/signup_stepper/PasswordField";
import ForgotPassword from "./pages/auth/signin/forgot-password/ForgotPassword";
import VerifyForgotEmail from "./pages/auth/signin/forgot-password/VerifyForgotEmail";
import NewPassword from "./pages/auth/signin/forgot-password/NewPassword";
import PasswordResetSuccess from "./pages/auth/signin/forgot-password/PasswordResetSuccess";
import BasicDetailsPartnerStepper from "./pages/auth/signup_partner/signup_stepper/BasicDetails";
import CoreIndicators from "./pages/auth/signup_partner/signup_stepper/CoreIndicators";
import OutcomeAreas from "./pages/auth/signup_partner/signup_stepper/OutcomeAreas";
import Mandate from "./pages/auth/signup_partner/signup_stepper/Mandate";
import PasswordPartnerField from "./pages/auth/signup_partner/signup_stepper/PasswordField";
import ProjectDescriptionSignUp from "./pages/auth/signup_wsp/signup_stepper/ProjectDescription";

export const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
        index: true,
      },

      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup-wsp/getting-started",
        element: <SignupWsp />,
      },
      {
        path: "/signup-wsp/basic-details",
        element: <BasicDetailsStepper />,
      },
      {
        path: "/signup-wsp/business-details",
        element: <BusinessDetailsStepper />,
      },
      {
        path: "/signup-wsp/management-details",
        element: <ManagementDetails />,
      },
      {
        path: "/signup-wsp/project-description",
        element: <ProjectDescriptionSignUp />,
      },
      
      {
        path: "/signup-wsp/password-setup",
        element: <PasswordField />,
      },

      {
        path: "/signup-ta/basic-details",
        element: <BasicDetailsTaStepper />,
      },
      {
        path: "/signup-ta/contact-details",
        element: <ContactDetails />,
      },
      {
        path: "/signup-ta/password-setup",
        element: <PasswordTaField />,
      },

      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/verify-forgot-password",
        element: <VerifyForgotEmail />,
      },
      {
        path: "/set-password/:userName/:userId",
        element: <NewPassword />,
      },
      {
        path: "/password-reset-success",
        element: <PasswordResetSuccess />,
      },

      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "/home",
            element: <Dashboard />,
          },

          {
            path: "/pipelines/new-projects/view-project",
            element: <ViewNewProject />,
          },

          {
            path: "/approve-wsp",
            element: <ApproveWsp />,
          },
          {
            path: "/onboard-wsp",
            element: <OnboardWsp />,
          },
          {
            path: "/dashboard",
            element: <ImpactDashboard />,
          },
          {
            path: "/water-sector",
            element: <WaterResource />,
          },
          {
            path: "/monitoring",
            element: <Monitoring />,
          },

          {
            path: "/single-water",
            element: <SingleWaterResource />,
          },
          {
            path: "/loan-offers",
            element: <LoanOffersList />,
          },
          {
            path: "/loan-offer-view/:id",
            element: <LoanOfferView />,
          },
          {
            path: "/project-development",
            element: <ProjectDevelopmentWsp />,
          },
          {
            path: "/invoice-list",
            element: <InvoiceList />,
          },
          {
            path: "/invoice-view/:id",
            element: <InvoiceView />,
          },
          {
            path: "/timelines",
            element: <Timelines />,
          },
          {
            path: "/account",
            element: <Account />,
          },
        ],
      },
      {
        path: "*",
        element: <p>404 - Error: Page not found</p>,
      },
    ],
  },
]);
