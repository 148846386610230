import React from 'react'
import LoanOfferTable from './LoanOfferTable'

function LoanOffersList() {
  return (
    <div className='w-[100%]'>
        <div className='offer-title mb-5'>Loan Offers</div>
        <LoanOfferTable/>
    </div>
  )
}

export default LoanOffersList