import React from 'react'
import { Progress } from 'antd';


const SingleBar = ({ percent, people, title }) => {
    const conicColors = {
        '0%': '#0170BD',
        '25%': '#0170BD',
        '50%': '#0873B9',
        '100%': '#DEEFFF',
    };
    return (
        <div className='flex flex-row ml-2 my-4'>
            <div className='w-[70%]'>
                <div className="flex flex-row justify-between w-[70%]">
                    <span className="text-blk font-bold text-[0.75rem]">{title}</span>
                </div>
                <Progress
                    percent={percent}
                    strokeColor={conicColors}
                    showInfo={false}
                    trailColor={"#DEEFFF"}
                />
            </div>
            <div className='flex flex-col ml-4'>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="17" viewBox="0 0 27 17" fill="none">
                        <path d="M3.51386 0C2.65085 0 1.91113 0.739712 1.91113 1.60273C1.91113 2.46574 2.65085 3.20549 3.51386 3.20549C4.37688 3.20549 5.11659 2.46574 5.11659 1.60273C5.11659 0.678068 4.37688 0 3.51386 0Z" fill="#0974BA" />
                        <path d="M1.78768 9.98656V15.6578C1.72604 16.0276 2.03425 16.3975 2.40412 16.4592C2.77398 16.5208 3.14388 16.2126 3.20552 15.8427C3.20552 15.7811 3.20552 15.7811 3.20552 15.7194V10.0482H3.76033V15.7194C3.82197 16.0893 4.13015 16.3975 4.56166 16.3359C4.93152 16.2742 5.1781 16.0277 5.1781 15.7194V3.63721H1.78768V9.98656Z" fill="#0974BA" />
                        <path d="M0 4.993V9.30807C0.0616439 9.67794 0.369819 9.98616 0.801326 9.92451C1.17119 9.86287 1.41777 9.61629 1.41777 9.30807V3.5752C0.678038 3.5752 0 4.19163 0 4.993Z" fill="#0974BA" />
                        <path d="M5.54785 3.5752V9.30807C5.54785 9.67794 5.85606 9.98618 6.22592 9.98618C6.59578 9.98618 6.90399 9.67794 6.90399 9.30807V4.993C6.96563 4.25327 6.34922 3.5752 5.54785 3.5752Z" fill="#0974BA" />
                        <path d="M13.5002 0C12.6372 0 11.8975 0.739712 11.8975 1.60273C11.8975 2.46574 12.6372 3.20549 13.5002 3.20549C14.3632 3.20549 15.103 2.46574 15.103 1.60273C15.103 0.678068 14.4248 0 13.5002 0Z" fill="#0974BA" />
                        <path d="M11.774 9.98656V15.6578C11.7124 16.0276 12.0206 16.3975 12.3904 16.4592C12.7603 16.5208 13.1301 16.2126 13.1918 15.8427C13.1918 15.7811 13.1918 15.7811 13.1918 15.7194V10.0482H13.7466V15.7194C13.8082 16.0893 14.1165 16.3975 14.548 16.3359C14.9178 16.2742 15.1644 16.0277 15.1644 15.7194V3.63721H11.774V9.98656Z" fill="#0974BA" />
                        <path d="M10.0479 4.993V9.30807C10.1095 9.67794 10.4177 9.98616 10.8493 9.92451C11.2191 9.86287 11.4657 9.61629 11.4657 9.30807V3.5752C10.6643 3.5752 10.0479 4.19163 10.0479 4.993Z" fill="#0974BA" />
                        <path d="M15.5957 3.5752V9.30807C15.5957 9.67794 15.9039 9.98618 16.2738 9.98618C16.6436 9.98618 16.9519 9.67794 16.9519 9.30807V4.993C17.0136 4.25327 16.3354 3.5752 15.5957 3.5752Z" fill="#0974BA" />
                        <path d="M23.4865 0C22.6235 0 21.8838 0.739712 21.8838 1.60273C21.8838 2.46574 22.6235 3.20549 23.4865 3.20549C24.3495 3.20549 25.0892 2.46574 25.0892 1.60273C25.0892 0.678068 24.4112 0 23.4865 0Z" fill="#0974BA" />
                        <path d="M21.8219 9.98656V15.6578C21.7602 16.0276 22.0684 16.3975 22.4383 16.4592C22.8082 16.5208 23.1781 16.2126 23.2397 15.8427C23.2397 15.7811 23.2397 15.7811 23.2397 15.7194V10.0482H23.8561V15.7194C23.9178 16.0893 24.226 16.3975 24.6575 16.3359C25.0274 16.2742 25.274 16.0277 25.274 15.7194V3.63721H21.8836L21.8219 9.98656Z" fill="#0974BA" />
                        <path d="M20.0342 4.993V9.30807C20.0958 9.67794 20.404 9.98616 20.8355 9.92451C21.2054 9.86287 21.4519 9.61629 21.4519 9.30807V3.5752C20.6506 3.5752 20.0342 4.19163 20.0342 4.993Z" fill="#0974BA" />
                        <path d="M25.582 3.5752V9.30807C25.582 9.67794 25.8902 9.98618 26.2601 9.98618C26.63 9.98618 26.9382 9.67794 26.9998 9.30807V4.993C26.9998 4.25327 26.3834 3.5752 25.582 3.5752Z" fill="#0974BA" />
                    </svg>
                </div>
                <div className='text-blk text-[0.875rem] font-[600]'>
                    {people} people
                </div>

            </div>
        </div>
    )
}

export default SingleBar