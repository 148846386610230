import { Table, Tag } from 'antd';
// import BreadCrumb from '../../layout/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';
// import { formatMoney } from '../../utils';

const breadList = [
    {
        title: 'Home',
        href: '/',
    },
    {
        title: 'New Projects',
    },
];


const dummyData = [
    {
        "result": "Increased access to safe Water, sanitation and IWRM services/products",
        "indicator": "Total number of people reached with safe Water, sanitation and IWRM services/products",
        "measurement": "3,000,000",
        "status": "target reached"

    },
    {
        "result": "Local partners can sustain the WASH solution in the long-term (10-15 years)",
        "indicator": "Locally available technology",
        "measurement": "2/3",
        "status": "target reached"

    },
    {
        "result": "Local partners can sustain the WASH solution in the long-term (10-15 years)",
        "indicator": "Revenue vs O&M Costs ",
        "measurement": "2/3",
        "status": "target reached"

    },
    {
        "result": "Local partners have viable business model and/or ensured Long-term financial sustainability",
        "indicator": "Net Profit Margin",
        "measurement": "2/3",
        "status": "target reached"

    },
];

const usrIcon = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='31'
        height='31'
        viewBox='0 0 31 31'
        fill='none'>
        <g clip-path='url(#clip0_1_4022)'>
            <path
                d='M15.4997 2.58301C8.36967 2.58301 2.58301 8.36967 2.58301 15.4997C2.58301 22.6297 8.36967 28.4163 15.4997 28.4163C22.6297 28.4163 28.4163 22.6297 28.4163 15.4997C28.4163 8.36967 22.6297 2.58301 15.4997 2.58301ZM15.4997 6.45801C17.6438 6.45801 19.3747 8.18884 19.3747 10.333C19.3747 12.4772 17.6438 14.208 15.4997 14.208C13.3555 14.208 11.6247 12.4772 11.6247 10.333C11.6247 8.18884 13.3555 6.45801 15.4997 6.45801ZM15.4997 24.7997C12.2705 24.7997 9.41592 23.1463 7.74967 20.6405C7.78842 18.0701 12.9163 16.6622 15.4997 16.6622C18.0701 16.6622 23.2109 18.0701 23.2497 20.6405C21.5834 23.1463 18.7288 24.7997 15.4997 24.7997Z'
                fill='#0170BD'
            />
        </g>
        <defs>
            <clipPath id='clip0_1_4022'>
                <rect width='31' height='31' fill='white' />
            </clipPath>
        </defs>
    </svg>
);

export default function MonitoringTable() {
    const { loading, newProjects } = useSelector((state) => state.fetch);

    const [value, setvalue] = useState('');

    // const arrayCopy = [...newProjects]
    //     ?.filter(
    //         (x) =>
    //             x?.projStatus === 'ACTIVE' ||
    //             x?.projStatus === 'DRAFT' ||
    //             x?.projStatus === 'REJECT'
    //     )
    //     ?.filter(
    //         (item) =>
    //             String(item?.accTradeName)
    //                 .toUpperCase()
    //                 .includes(String(value).toUpperCase()) ||
    //             String(item?.accCounty)
    //                 .toUpperCase()
    //                 .includes(String(value).toUpperCase())
    //     );







    useEffect(() => { }, [newProjects, value]);

    useEffect(() => {

    }, []);

    const columns = [
        {
            title: 'Result',
            dataIndex: 'accTradeName',
            render: (item) => (
                <div className='flex items-center'>
                    {usrIcon}
                    <span className='ml-[1.37rem]'>{item}</span>
                </div>
            ),
        },
        {
            title: 'Indicator',
            dataIndex: 'accCounty',
        },
        {
            // title: 'Performance',
            dataIndex: 'Measurement ',
            render: (item) => <span>{(item)}</span>,
        },
        {
            title: ' Quarterly Progress',
            dataIndex: 'projLoanAmount',
            render: (item) => <span>{(item)}</span>,
        },
        {
            title: 'Status',
            key: 'action',
            render: (item) => (
                <button

                    className='flex items-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19'
                        height='13'
                        viewBox='0 0 19 13'
                        fill='none'>
                        <path
                            d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
                            fill='#0170BD'
                        />
                    </svg>
                    <span className='ml-[.44rem] table_view_txt'>View</span>
                </button>
            ),
        },
    ];

    return (
        <>
            <div className='w-[100%] flex flex-col'>
                <div className='w-full mt-[2.75rem]'>
                    <Table
                        loading={loading}
                        pagination={{
                            defaultPageSize: 7,
                            hideOnSinglePage: true,
                            pageSizeOptions: [7, 15, 50, 100],
                        }}
                        columns={columns}
                    // dataSource={arrayCopy}
                    />
                </div>
            </div>
        </>
    );
}


