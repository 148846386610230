import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Table } from "antd";
import { save } from "../../../../features/save/saveSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCalculate,
  fetchWspProject,
} from "../../../../features/wsp/wspSlice";
import toast from "react-hot-toast";
import { formatMoney, numberWithCommas } from "../../../../utils";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  }, [editable, dataIndex, record, form]);

  const saveN = async () => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            validator: (_, value) =>
              value >= 0
                ? Promise.resolve()
                : Promise.reject(new Error(`${title} must be non-negative.`)),
          },
        ]}
      >
        <InputNumber
          min={0}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          className="input"
          ref={inputRef}
          onPressEnter={saveN}
          onBlur={saveN}
        />
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const FinancingTable = () => {
  const dispatch = useDispatch();
  const { wspApplications, calculations, calcLoading, wspLoading } =
    useSelector((state) => state.wsp);
  const { user } = useSelector((state) => state.auth);

  const loanAmounts = wspApplications?.projLoanAmount;
  const loanee =
    wspApplications?.proj3rdpartySum && wspApplications?.projSswpSum
      ? loanAmounts -
        (wspApplications?.projSswpSum + wspApplications?.proj3rdpartySum)
      : wspApplications?.projSswpSum
      ? loanAmounts - wspApplications?.projSswpSum
      : 0;

  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      name: "Loan",
      sum: loanee,
      percent: wspApplications?.projLoanCalculated,
    },
    {
      key: "2",
      name: "Third Party",
      sum: wspApplications?.proj3rdpartySum,
      percent: wspApplications?.proj3rdpartyCalculated,
    },
    {
      key: "3",
      name: "Your Contribution",
      sum: wspApplications?.projSswpSum,
      percent: wspApplications?.projSswpCalculated,
    },
    {
      key: "4",
      name: "Total",
      sum: wspApplications?.projLoanAmount,
      percent: wspApplications?.projTotalCalculated,
    },
  ]);

  useEffect(() => {
    if (wspApplications) {
      setDataSource([
        {
          key: "1",
          name: "Loan",
          sum: formatMoney(loanee),
          percent: wspApplications?.projLoanCalculated,
        },
        {
          key: "2",
          name: "Third Party",
          sum: wspApplications?.proj3rdpartySum,
          percent: wspApplications?.proj3rdpartyCalculated,
        },
        {
          key: "3",
          name: "Your Contribution",
          sum: wspApplications?.projSswpSum,
          percent: wspApplications?.projSswpCalculated,
        },
        {
          key: "4",
          name: "Total",
          sum: formatMoney(wspApplications?.projLoanAmount),
          percent: wspApplications?.projTotalCalculated,
        },
      ]);
    }
  }, [wspApplications, loanee]);


  const defaultColumns = [
    {
      title: "Item",
      dataIndex: "name",
      editable: false,
    },
    {
      title: "Sum (KES)",
      dataIndex: "sum",
      editable: (record) => record?.key === "2" || record?.key === "3",
    },
    {
      title: "% calculated",
      dataIndex: "percent",
      editable: false,
    },
  ];

  const handleSave = async (row) => {
    const newData = dataSource?.map((item) =>
      item.key === row.key ? { ...item, ...row } : item
    );
    setDataSource(newData);
    if (row?.key == 3) {
      if (row?.sum < 0.2 * loanAmounts) {
        toast.error(
          `Value must be greater than ${
            0.2 * loanAmounts
          } which is 20% of total loan applied`
        );
      } else {
        const res = await dispatch(
          fetchCalculate({
            projId: wspApplications?.projId,
            id: row?.key,
            sum: row?.sum,
            loan: loanee,
          })
        );
        if (res?.payload?.success) {
          fetchWspProjectData();
        }
      }
    } else {
      const res = await dispatch(
        fetchCalculate({
          projId: wspApplications?.projId,
          id: row?.key,
          sum: row?.sum,
          loan: loanee,
        })
      );
      if (res?.payload?.success) {
        fetchWspProjectData();
      }
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns?.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable:
        typeof col?.editable === "function"
          ? col?.editable(record)
          : col?.editable,
      dataIndex: col?.dataIndex,
      title: col?.title,
      handleSave,
    }),
  }));

  // const columns = defaultColumns?.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       editable: col.editable,
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       handleSave,
  //     }),
  //   };
  // });

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={wspLoading}
      />
    </div>
  );
};

export default FinancingTable;
