import React, { useRef, useState } from "react";
import { Form, Input, Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import FlatRateTable from "./tables/FlatRateTable";
import { save } from "../../../features/save/saveSlice";
import VolumetricTable from "./tables/VolumetricTable";
import ChargesTable from "./tables/ChargesTable";

function FinancialSummary({ prev, next, nextStep }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wspApplications } = useSelector((state) => state.wsp);

  const [formData, setFormData] = useState();

  const onFinish = async (data) => {};

  const [flatValue, setFlatValue] = useState(wspApplications?.projPayCapable);
  const onChange = async (e) => {
    setFlatValue(e.target.value);
    const res = await dispatch(
      save({
        projTariffType: e.target.value,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
  };

  const [otherValue, setOtherValue] = useState(
    wspApplications?.projOtherCharges
  );
  const onChangeOther = async (e) => {
    setOtherValue(e.target.value);
    const res = await dispatch(
      save({
        projOtherCharges: e.target.value,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
  };

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">d) Financial Information</div>

        <div className="profile_sub mt-[.94rem]">
          Summary of Financial Position
        </div>
      </div>

      <div className=" px-[3.88rem]">
        <div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
            }}
            className="w-[600px] mt-[1.13rem]"
            form={form}
          >
            <div className="flex flex-col">
              <div className="mngt-tool">
                Is the tariff the same for every customer?
              </div>

              <Radio.Group
                className="mt-[.87rem]"
                onChange={onChange}
                value={flatValue}
              >
                <Radio className="mngt-tool" value={"Yes"}>
                  Yes
                </Radio>
                <Radio className="mngt-tool" value={"No"}>
                  No
                </Radio>
              </Radio.Group>

              <div className="profile_sub mt-[2.25rem] mb-[.69rem]">
                Fill in the flat rate table below
              </div>
              <FlatRateTable />

              <div className="profile_sub mt-[2.25rem] mb-[.69rem]">
                Fill in the volumetric table below
              </div>
              <VolumetricTable />

              <div className="mngt-tool mt-[2.56rem]">
                Are there other charges?
              </div>

              <Radio.Group
                className="mt-[.87rem]"
                onChange={onChangeOther}
                value={otherValue}
              >
                <Radio className="mngt-tool" value={"Yes"}>
                  Yes
                </Radio>
                <Radio className="mngt-tool" value={"No"}>
                  No
                </Radio>
              </Radio.Group>

              <div className="profile_sub mt-[2.25rem] mb-[.69rem]">
                Fill in the charges table below
              </div>
              <ChargesTable />
            </div>

            <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
              <button onClick={() => next()} className="cstm-btn">
                Next
              </button>
              <button onClick={prev} className="border-btn mt-[.75rem]">
                Previous
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default FinancialSummary;
