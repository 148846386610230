import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  offerData: [],
  singleOfferData:{},
  invData:[]
};
 
export const fetchLoanTerms = createAsyncThunk(
  "offerSlice/fetchLoanTerms",
  async (data) => {
    const response = await axios.get(
      `${url}/usr/fetchLoanTermConditions.action?ltcProjId=${data?.ltcProjId}`
    ); 
    return response.data?.data?.result;
  }
);

export const fetchSingleLoanTerms = createAsyncThunk(
  "offerSlice/fetchSingleLoanTerms",
  async (data) => {
    const response = await axios.get(
      `${url}/usr/fetchLoanTermConditions.action?ltcId=${data?.ltcId}`
    ); 
    return response.data?.data?.result;
  }
);
 
export const fetchInvoice = createAsyncThunk(
	"bankSlice/fetchInvoice",
	async (data) => {
	  const response = await axios.get(
		`${url}/dash/fetchProjectDevelopment.action?projId=${data?.projId}`
	  ); 
	  return response.data?.data?.result;
	}
  );
  

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchLoanTerms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLoanTerms.fulfilled, (state, action) => {
        state.loading = false;
        state.offerData = action.payload;
      })
      .addCase(fetchLoanTerms.rejected, (state) => {
        state.loading = false;
        state.offerData = [];
      }) 

      .addCase(fetchSingleLoanTerms.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleLoanTerms.fulfilled, (state, action) => {
        state.loading = false;
        state.singleOfferData = action.payload;
      })
      .addCase(fetchSingleLoanTerms.rejected, (state) => {
        state.loading = false;
        state.singleOfferData ={}
      }) 

      .addCase(fetchInvoice.pending, (state) => {
				state.loading = true;
			  })
			  .addCase(fetchInvoice.fulfilled, (state, action) => {
				state.loading = false;
				state.invData = action.payload;
			  })
			  .addCase(fetchInvoice.rejected, (state) => {
				state.loading = false;
			  })
      
  },
});

export default offerSlice.reducer;
export const {} = offerSlice.actions;
